import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import BottomNavigation from '../../components/BottomNavigation';
import { jwtDecode } from 'jwt-decode'; // Importation correcte
import { Link } from 'react-router-dom'; // Import pour la navigation
import { ChevronRightIcon, EyeIcon, MapPinIcon, PackageIcon, XCircleIcon } from 'lucide-react'; // Import des icônes Lucide React

type Product = {
    id: number;
    name: string;
    image_1: string; // Pour stocker l'image principale
};

interface OrderItem {
    product_id: number;
    name: string;
    price: number;
    size: string;
    quantity: number;
}

interface Order {
    id: number;
    created_at: string;
    total_price: number | string;
    status: string;
    order_details: OrderItem[];
    stripe_payment_intent: string;
    address_id: number; // Ajout de l'ID de l'adresse
}

interface Address {
    first_name: string;
    last_name: string;
    address: string;
    address_2: string;
    postal_code: string;
    city: string;
    country: string;
}

interface BadgeProps {
    children: React.ReactNode;
    variant?: "default" | "success" | "processing";
}

const Badge: React.FC<BadgeProps> = ({ children, variant = "default" }) => {
    const baseClasses = "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold";
    const variantClasses = {
        default: "bg-primary text-primary-foreground",
        success: "bg-green-500 text-white",
        processing: "bg-yellow-500 text-white",
    };

    return (
        <span className={`${baseClasses} ${variantClasses[variant]}`}>
            {children}
        </span>
    );
};

export default function OrdersPage() {
    const [orders, setOrders] = useState<Order[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [products, setProducts] = useState<{ [key: number]: Product }>({});
    const [addresses, setAddresses] = useState<{ [key: number]: Address }>({});

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setErrorMessage('Token non trouvé, veuillez vous reconnecter.');
                    return;
                }

                const decodedToken = jwtDecode<{ userId: string }>(token);
                const user_id = decodedToken.userId;

                const response = await axios.get(`/api/orders/user/${user_id}`);

                const parsedOrders = response.data.orders.map((order: any) => ({
                    ...order,
                    order_details: JSON.parse(order.order_details),
                }));

                setOrders(parsedOrders);

                // Récupération des adresses pour chaque commande
                parsedOrders.forEach(async (order: Order) => {
                    const addressResponse = await axios.get(`/api/address/${order.address_id}`);
                    setAddresses((prevAddresses) => ({
                        ...prevAddresses,
                        [order.address_id]: addressResponse.data.address,
                    }));
                });
            } catch (error) {
                setErrorMessage('Erreur lors de la récupération des commandes.');
                console.error(error);
            }
        };

        fetchOrders();
    }, []);

    // Fonction pour récupérer les informations du produit via son product_id
    const fetchProductDetails = async (product_id: number) => {
        try {
            if (!products[product_id]) {
                const response = await axios.get(`/api/products/${product_id}`);
                setProducts((prevProducts) => ({
                    ...prevProducts,
                    [product_id]: response.data,
                }));
            }
        } catch (error) {
            console.error('Erreur lors de la récupération du produit:', error);
        }
    };

    // Récupérer l'image du produit via son product_id
    const getProductImage = (product_id: number) => {
        const product = products[product_id];
        return product ? product.image_1 : '/placeholder.svg';
    };

    // Fonction pour formater la date au format "jour (nom du mois) année"
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        return date.toLocaleDateString('fr-FR', options);
    };

    // Fonction pour récupérer l'adresse via l'address_id
    const getAddress = (address_id: number) => {
        const address = addresses[address_id];
        if (!address) return 'Chargement...';

        return (
            <>
                <p className="text-lg font-semibold">{address.first_name} {address.last_name}</p>
                <p className="text-lg">{address.address}</p>
                {address.address_2 && <p className="text-lg">{address.address_2}</p>}
                <p className="text-lg">{address.postal_code} {address.city}</p>
                <p className="text-lg">{address.country}</p>
            </>
        );
    };

    // Fonction pour annuler la commande (placeholder, tu peux ajouter une logique spécifique)
    const cancelOrder = (order_id: number) => {
        console.log(`Commande ${order_id} annulée`);
        // Implémente la logique d'annulation ici
    };

    return (
        <div>
            <div className="container mx-auto px-4 py-8 font-binaria">
                <Header />
                <h1 className="text-3xl font-black mb-6">Vos commandes</h1>
                <div className="space-y-6">
                    {orders.length === 0 ? (
                        <div
                            className="bg-orange-100 border border-orange-400 text-orange-700 px-4 py-3 rounded relative mb-4">
                            <strong
                                className="font-bold">{errorMessage || "Vous n'avez encore jamais passé de commande"}</strong>
                        </div>
                    ) : (
                        orders.map((order) => (
                            <div key={order.id} className="bg-white shadow-md rounded-lg overflow-hidden">
                                <div className="p-6">
                                    <div className="flex justify-between items-start mb-4">
                                        <div>
                                            <h2 className="text-xl font-black">Commande du {formatDate(order.created_at)}</h2>
                                            <p className="text-sm text-gray-500">N° de commande : {order.stripe_payment_intent}</p>
                                        </div>
                                        <div className="text-right">
                                            <Badge variant={order.status === "Delivered" ? "success" : "processing"}>
                                                {order.status}
                                            </Badge>
                                            <p className="font-medium text-2xl mt-2">{Number(order.total_price).toFixed(2)} €</p>
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        {/* Articles de la commande */}
                                        <div className="w-1/2 pr-4">
                                            <h3 className="text-lg font-black mb-4">Articles de la commande</h3>
                                            <div className="space-y-4">
                                                {order.order_details.map((item) => {
                                                    fetchProductDetails(item.product_id);
                                                    return (
                                                        <div key={item.product_id} className="flex items-center justify-between space-x-4">
                                                            <div className="flex items-center">
                                                                <img
                                                                    src={getProductImage(item.product_id)}
                                                                    alt={item.name}
                                                                    className="w-20 h-20 object-cover rounded"
                                                                />
                                                                <div className="ml-4">
                                                                    <h4 className="font-medium">{item.name}</h4>
                                                                    <p className="text-sm text-gray-600">
                                                                        Taille : {item.size} | Quantité : {item.quantity}
                                                                    </p>
                                                                    <p className="text-sm text-gray-600">Prix unitaire : {item.price.toFixed(2)} €</p>
                                                                </div>
                                                                <Link
                                                                    to={`/productpage/${item.product_id}`}
                                                                    className="flex items-center text-gray-500 hover:text-orange-600 ml-7"
                                                                >
                                                                    <ChevronRightIcon className="w-4 h-4 mr-2" />
                                                                    <span>Voir le produit</span>
                                                                    <EyeIcon className="w-4 h-4 ml-2 " />
                                                                </Link>
                                                            </div>

                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        {/* Adresse de livraison */}
                                        <div className="w-1/2 pl-4">
                                            <h4 className="text-g font-black mb-2 flex items-center">
                                                Adresse de livraison
                                                <MapPinIcon className="w-5 h-5 ml-2 mb-1" />
                                            </h4>
                                            <div className="text-sm text-gray-700">
                                                {getAddress(order.address_id)}
                                            </div>
                                            {/* Bouton de suivi de colis sous l'adresse */}
                                            <div className="mt-4">
                                                <button className="bg-blue-600 text-white px-4 py-2 rounded-md flex items-center hover:bg-blue-500">
                                                    <PackageIcon className="w-4 h-4 mr-2" />
                                                    Voir le suivi de colis
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    {/* Bouton pour annuler la commande */}
                                    <div className="mt-6 flex justify-end">
                                        <button
                                            onClick={() => cancelOrder(order.id)}
                                            className="bg-red-600 text-white px-4 py-2 rounded-md flex items-center hover:bg-red-500"
                                        >
                                            <XCircleIcon className="w-4 h-4 mr-2" />
                                            Annuler ma commande
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <br /><br />
            </div>
            <BottomNavigation />
            <Footer />
        </div>
    );
}
import React from 'react';
import HeroPresentation from '../components/HomePage/HeroPresentation';

const DebugPage: React.FC = () => {
    return (
        <div>
            <HeroPresentation />
        </div>
    );
};

export default DebugPage;

import React from 'react';
import { AlertCircle, CheckCircle } from 'lucide-react';
import ReactDOM from 'react-dom';

interface NotificationProps {
    type: 'error' | 'success';
    message: string;
    isVisible: boolean;
}

export default function NotificationPortal({ type, message, isVisible }: NotificationProps) {
    if (!isVisible) return null;

    const backgroundColor = type === 'success' ? 'bg-green-500' : 'bg-yellow-100';
    const textColor = type === 'success' ? 'text-white' : 'text-yellow-700';
    const borderColor = type === 'success' ? 'border-green-500' : 'border-yellow-500';
    const Icon = type === 'success' ? CheckCircle : AlertCircle;

    return ReactDOM.createPortal(
        <div className={`fixed bottom-4 right-4 p-4 ${backgroundColor} border-l-4 ${borderColor} shadow-md z-[9999]`}>
            <div className={`flex items-center ${textColor}`}>
                <Icon className="w-5 h-5 mr-2" />
                <span>{message}</span>
            </div>
        </div>,
        document.body // Injecte dans le body pour être toujours au-dessus
    );
}

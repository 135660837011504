import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const PrivacyPolicyPage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                <div className="w-3/4 mx-auto bg-gray-100 p-8 rounded-lg shadow-lg font-bold font-binaria">
                    <h1 className="text-4xl font-bold mb-8">Mentions Légales et Politique de Confidentialité</h1>

                    {/* Mentions légales */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Mentions Légales</h2>
                        <p><strong>Éditeur :</strong></p>
                        <p>Add-Last SAS</p>
                        <p><a href="https://add-last.com" className="text-red-500 hover:underline">Add-Last</a></p>
                        <p>44 avenue barbes, 93420 Villepinte</p>
                        <br/>
                        <p><strong>Gérance :</strong></p>
                        <p>Président : <a href="mailto:atrewind404@gmail.com" className="text-red-500 hover:underline">MERCIER Thibault</a></p>
                        <p>Directeur général : <a href="mailto:alexis.martineau.95@gmail.com" className="text-red-500 hover:underline">MARTINEAU Alexis</a></p>
                        <br/>
                        <p><strong>Hébergeur :</strong></p>
                        <p>Le site <a href="https://add-last.com" className="text-red-500 hover:underline">Add-Last</a> est hébergé par la société OVH :</p>
                        <p>RCS Lille Métropole 424 761 419 00045</p>
                        <p>2 rue Kellermann - 59100 Roubaix - France</p>
                        <p><a href="https://www.ovh.com" className="text-red-500 hover:underline">OVH</a></p>
                        <br/>
                        <p>
                            Conformément aux dispositions du code de la propriété intellectuelle, toute personne qui souhaiterait reproduire
                            le contenu rédactionnel de ce site en vue d’une communication au public doit impérativement solliciter une autorisation
                            préalable auprès d’un des contacts mentionnés ci-dessus.
                        </p>
                    </section>

                    {/* Mise en garde */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Mise en Garde</h2>
                        <p>
                            Add-Last s’engage à vérifier le contenu des liens hypertextes pointant sur des pages extérieures au présent site
                            au moment de leur création. Néanmoins, le contenu des sites visés peut changer postérieurement à cette vérification.
                            Nous invitons donc les visiteurs à la plus grande prudence.
                        </p>
                    </section>

                    {/* Politique de confidentialité */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Politique de Confidentialité</h2>
                        <p>
                            Nous nous engageons à protéger votre vie privée. Contactez-nous si vous avez des questions concernant
                            l’utilisation de vos données personnelles. En utilisant ce site ou nos services, vous acceptez le traitement
                            de vos données personnelles tel que décrit dans cette politique de confidentialité.
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Définitions</h3>
                        <p><span className="font-bold">Données personnelles :</span> toute information relative à une personne physique identifiée ou identifiable.</p>
                        <p><span className="font-bold">Traitement :</span> toute opération effectuée sur des données personnelles.</p>
                        <p><span className="font-bold">Enfant :</span> une personne physique de moins de 16 ans.</p>
                        <p><span className="font-bold">Nous :</span> Add-Last SAS au capital de 1000€</p>
                        <br/>

                        <h3 className="text-xl font-bold mb-4">Principes de Protection des Données</h3>
                        <ul className="list-disc list-inside">
                            <li>Le traitement est légal, juste, transparent.</li>
                            <li>Le traitement est limité à l’objectif.</li>
                            <li>Nous collectons uniquement la quantité minimale de données nécessaire.</li>
                            <li>Nous ne conservons pas vos données plus longtemps que nécessaire.</li>
                            <li>Nous faisons de notre mieux pour garantir l'exactitude et la confidentialité des données.</li>
                        </ul>
                        <br/>

                        <h3 className="text-xl font-bold mb-4">Droits du Sujet de Données</h3>
                        <ul className="list-disc list-inside">
                            <li>Droit à l’information sur le traitement des données.</li>
                            <li>Droit d’accès aux données collectées.</li>
                            <li>Droit de rectification ou d’effacement des données.</li>
                            <li>Droit de s’opposer au traitement des données.</li>
                            <li>Droit de retirer votre consentement à tout moment.</li>
                        </ul>
                        <br/>

                        <h3 className="text-xl font-bold mb-4">Données que Nous Collectons</h3>
                        <p><span className="font-bold">Informations fournies :</span> Cela inclut votre nom, adresse e-mail, etc., nécessaires pour vous fournir nos services.</p>
                        <p><span className="font-bold">Informations collectées automatiquement :</span> Cookies et autres technologies similaires pour analyser le comportement des utilisateurs.</p>
                        <p><span className="font-bold">Informations accessibles au public :</span> Nous pouvons recueillir des informations publiquement disponibles.</p>
                        <br/>

                        <h3 className="text-xl font-bold mb-4">Comment Nous Utilisons Vos Données</h3>
                        <ul className="list-disc list-inside">
                            <li>Pour vous fournir nos services.</li>
                            <li>Pour améliorer votre expérience utilisateur.</li>
                            <li>Pour remplir des obligations légales ou contractuelles.</li>
                        </ul>
                        <br/>

                        <h3 className="text-xl font-bold mb-4">Sécurité de Vos Données</h3>
                        <p>
                            Nous mettons tout en œuvre pour protéger vos données personnelles. Nous utilisons des protocoles sécurisés pour le transfert de données et suivons des mesures strictes de sécurité.
                        </p>
                        <br/>

                        <h3 className="text-xl font-bold mb-4">Cookies et Technologies Similaires</h3>
                        <p>
                            Nous utilisons des cookies pour suivre le comportement des utilisateurs, gérer le site et analyser les performances. Vous pouvez contrôler vos cookies via les paramètres de votre navigateur.
                        </p>
                    </section>

                    {/* Informations de contact */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Informations de Contact</h2>
                        <p>
                            Pour toute question concernant cette politique de confidentialité, vous pouvez nous contacter par e-mail à :
                            <a href="mailto:atrewind404@gmail.com" className="text-red-500 hover:underline"> atrewind404@gmail.com</a>.
                        </p>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default PrivacyPolicyPage;
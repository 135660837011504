type IconlyIconProps = {
    size?:number;
    color?:string;
}

export const IconlyPlus = ({ size = 24, color = "#000000" }: IconlyIconProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.8279 13.2847H12.9999V16.1087C12.9999 16.5227 12.6639 16.8587 12.2499 16.8587C11.8359 16.8587 11.4999 16.5227 11.4999 16.1087V13.2847H8.67194C8.25794 13.2847 7.92194 12.9487 7.92194 12.5347C7.92194 12.1207 8.25794 11.7847 8.67194 11.7847H11.4999V8.96067C11.4999 8.54667 11.8359 8.21067 12.2499 8.21067C12.6639 8.21067 12.9999 8.54667 12.9999 8.96067V11.7847H15.8279C16.2419 11.7847 16.5779 12.1207 16.5779 12.5347C16.5779 12.9487 16.2419 13.2847 15.8279 13.2847ZM12.2499 2.29767C4.69094 2.29767 2.01294 4.97567 2.01294 12.5347C2.01294 20.0937 4.69094 22.7717 12.2499 22.7717C19.8079 22.7717 22.4869 20.0937 22.4869 12.5347C22.4869 4.97567 19.8079 2.29767 12.2499 2.29767Z" fill={color}></path>
        </svg>
    )
}
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const faqs = [
    {
        question: "Quand ma commande sera-t-elle livrée ?",
        answer: "Votre commande sera livrée sous 5 à 7 jours ouvrés après expédition."
    },
    {
        question: "Puis-je modifier ma commande après validation ?",
        answer: "Non, une fois la commande validée, elle ne peut plus être modifiée."
    },
    {
        question: "Quels sont les modes de paiement acceptés ?",
        answer: "Nous acceptons les paiements par carte bancaire, PayPal."
    },
    {
        question: "Comment retourner un article ?",
        answer: "Vous pouvez retourner un article dans un délai de 30 jours après réception de votre commande."
    }
];

const FaqPage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                <div className="w-3/4 mx-auto my-16 p-8 bg-gray-100 rounded-lg shadow-lg">
                    <h1 className="font-binaria text-4xl font-bold mb-8">FAQ - Questions Fréquemment Posées</h1>

                    {/* Section FAQ */}
                    <div className="space-y-6 mb-8">
                        {faqs.map((faq, index) => (
                            <div key={index}>
                                <h2 className="font-binaria text-xl font-semibold mb-2">{faq.question}</h2>
                                <p className="font-binaria text-gray-700">{faq.answer}</p>
                            </div>
                        ))}
                    </div>

                    {/* Section pour poser une question */}
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <h2 className="font-binaria text-2xl font-bold mb-4">Vous avez une question ?</h2>
                        <textarea
                            placeholder="Écrivez votre question ici..."
                            className="font-binaria w-full p-2 border border-gray-300 rounded-md mb-4"
                            rows={4}
                        />
                        <button
                            className="font-binaria bg-orange-500 text-white px-6 py-2 rounded-md hover:bg-orange-600"
                        >
                            Envoyer la question
                        </button>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default FaqPage;
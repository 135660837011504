import axios from 'axios';

const API_URL = '/api/products';

// Créer un produit
export const createProduct = async (productData: any) => {
    const token = localStorage.getItem('token');
    return axios.post(API_URL, productData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

// Récupérer tous les produits
export const getAllProducts = async () => {
    const token = localStorage.getItem('token');
    return axios.get(API_URL, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

// Mettre à jour un produit
export const updateProduct = async (id: number, productData: any) => {
    const token = localStorage.getItem('token');
    return axios.put(`${API_URL}/${id}`, productData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

// Supprimer un produit
export const deleteProduct = async (id: number) => {
    const token = localStorage.getItem('token');
    return axios.delete(`${API_URL}/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

// Synchroniser les votes et mettre à jour les likes
export const syncAllVotes = async () => {
    const token = localStorage.getItem('token');
    return axios.post('/api/votes/sync-likes', {}, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};


// Récupérer l'historique des likes pour un produit
export const getProductLikesHistory = async (productId) => {
    return axios.get(`/api/products/${productId}/likes-history`);
};

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import BottomNavigation from "../../components/BottomNavigation";

const AddressesPage = () => {
    const [formData, setFormData] = useState({
        alias: '',
        first_name: '',
        last_name: '',
        address: '',
        address_2: '',
        postal_code: '',
        city: '',
        country: '',
        phone: ''
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Récupérer l'adresse de l'utilisateur
    const fetchUserAddress = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setErrorMessage('Token non trouvé, veuillez vous reconnecter.');
                return;
            }

            const response = await axios.get('/api/address/addresses', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.address) {
                // Charger l'adresse existante
                setFormData(response.data.address);
            } else {
                setErrorMessage("Aucune adresse trouvée.");
            }
        } catch (error) {
            setErrorMessage('Erreur lors de la récupération de l\'adresse.');
            console.error(error);
        }
    };

    useEffect(() => {
        fetchUserAddress(); // Appeler la fonction de récupération de l'adresse au montage
    }, []);

    // Gérer les changements dans le formulaire
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Soumettre les modifications
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setErrorMessage('Token non trouvé, veuillez vous reconnecter.');
                return;
            }

            // Mettre à jour l'adresse existante
            await axios.put(
                '/api/address/addresses',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setSuccessMessage('Adresse mise à jour avec succès.');
        } catch (error) {
            setErrorMessage('Erreur lors de la mise à jour de l\'adresse.');
            console.error(error);
        }
    };

    return (
        <div className="flex flex-col min-h-screen font-binaria">
            <Header />
            <main className="flex-grow p-8">
                <h1 className="text-4xl font-bold text-center mb-8">Votre adresse</h1>

                {/* Formulaire de mise à jour de l'adresse */}
                <form onSubmit={handleSubmit} className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto">
                    <h2 className="text-2xl font-semibold mb-4">Mettre à jour l'adresse</h2>

                    {/* Alias */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Alias</label>
                        <input
                            type="text"
                            name="alias"
                            value={formData.alias}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Alias de l'adresse (Ex: Maison, Bureau, etc.)"
                        />
                    </div>

                    {/* Prénom */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Prénom</label>
                        <input
                            type="text"
                            name="first_name"
                            value={formData.first_name}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Prénom"
                        />
                    </div>

                    {/* Nom */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Nom</label>
                        <input
                            type="text"
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Nom"
                        />
                    </div>

                    {/* Adresse */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Adresse</label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Adresse"
                        />
                    </div>

                    {/* Complément d'adresse */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Complément d'adresse (optionnel)</label>
                        <input
                            type="text"
                            name="address_2"
                            value={formData.address_2}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Complément d'adresse"
                        />
                    </div>

                    {/* Code postal */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Code postal</label>
                        <input
                            type="text"
                            name="postal_code"
                            value={formData.postal_code}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Code postal"
                        />
                    </div>

                    {/* Ville */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Ville</label>
                        <input
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Ville"
                        />
                    </div>

                    {/* Pays */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Pays</label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Pays"
                        />
                    </div>

                    {/* Téléphone */}
                    <div className="mb-4">
                        <label className="block text-lg font-semibold mb-2">Téléphone</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Téléphone"
                        />
                    </div>

                    {/* Message d'erreur et de succès */}
                    {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
                    {successMessage && <div className="text-green-500 mb-4">{successMessage}</div>}

                    {/* Bouton Soumettre */}
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="bg-orange-500 text-white font-semibold px-6 py-3 rounded-md hover:bg-orange-600 transition-all"
                        >
                            Mettre à jour
                        </button>
                    </div>
                </form>
                <br/><br/>
                <BottomNavigation />
            </main>
            <Footer />
        </div>
    );
};

export default AddressesPage;

import { Link, useNavigate } from 'react-router-dom';
import { IconlyLeftArrow} from "./icons/IconlyLeftArrow";
import {IconlyHome} from "./icons/IconlyHome";
import IconlyCart from "./icons/IconlyCart";
import React from "react";

const BottomNavigation = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Permet de retourner à la page précédente
    };

    return (
        <div className="flex justify-items-start items-center p-4 bg-white">
            <button
                onClick={goBack}
                className="flex items-center text-black hover:text-[rgb(226,85,53)] font-semibold text-sm mr-2"
            >
                <IconlyLeftArrow size={24} />
                Retour à votre compte
            </button>
            <Link to="/" className="flex items-center text-black hover:text-[rgb(226,85,53)] font-semibold text-sm">
                <IconlyHome size={24} />
                Accueil
            </Link>
        </div>
    );
};

export default BottomNavigation;

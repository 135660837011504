import React, { useEffect, useState } from 'react';
import WordPullUp from "../magicui/word-pull-up";
import { useNavigate } from 'react-router-dom';

const HeroPresentation: React.FC = () => {
    const [progress, setProgress] = useState(0);
    const [progressBarValue, setProgressBarValue] = useState(0);
    const [heroProduct, setHeroProduct] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [finalProgress, setFinalProgress] = useState(0);

    const navigate = useNavigate();  // Hook de navigation

    useEffect(() => {
        const fetchHeroProduct = async () => {
            try {
                const response = await fetch('http://localhost:3001/api/hero-product');
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération du produit en avant');
                }
                const data = await response.json();
                const likesProgress = (data.likes_current / data.likes_target) * 100;
                setFinalProgress(Math.min(likesProgress, 100));

                setHeroProduct(data);
                setLoading(false);
            } catch (err: any) {
                setError(err.message);
                setLoading(false);
            }
        };
        fetchHeroProduct();
    }, []);

    useEffect(() => {
        let progressValue = 0;
        const intervalTime = 1000 / 60;
        const duration = 2500;
        const increment = finalProgress / (duration / intervalTime);

        const interval = setInterval(() => {
            progressValue += increment;
            setProgress(Math.min(progressValue, finalProgress));
            setProgressBarValue(Math.ceil(progressValue));

            if (progressValue >= finalProgress) {
                clearInterval(interval);
            }
        }, intervalTime);

        return () => clearInterval(interval);
    }, [finalProgress]);

    // Fonction pour gérer la redirection vers la page du produit
    const handlePreorderClick = () => {
        if (heroProduct && heroProduct.id) {
            navigate(`/productpage/${heroProduct.id}`);  // Redirige vers la ProductPage avec l'ID du produit
        }
    };

    if (loading) {
        return <p>Chargement...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <section className="w-full h-auto flex flex-col justify-center items-center bg-transparent px-4 md:px-12 mx-auto">
            <div className="w-full h-full py-8 space-y-6 flex flex-col justify-center items-center">
                {/* Image responsively scaled */}
                <div className="relative w-full h-56 md:h-96 lg:h-[40vh]">
                    <img
                        src={heroProduct.hero_image}
                        alt={heroProduct.hero_title}
                        className="w-full h-full object-contain"
                    />
                </div>

                {/* Title and details */}
                <div className="space-y-2 text-center w-full mb-4">
                    <WordPullUp
                        className="text-2xl md:text-5xl lg:text-7xl font-bold tracking-tight text-black"
                        words={heroProduct.hero_title || "Titre du produit"}
                    />
                    <p className="text-lg md:text-2xl font-semibold text-gray-500">
                        {heroProduct.hero_description || "Description du produit"}
                    </p>
                </div>

                {/* Fancy progress bar */}
                <div className="w-4/5 md:w-3/5">  {/* Barre de progression responsive */}
                    <div className="flex justify-center mb-4">
                        <span className="text-4xl md:text-6xl font-bold text-primary">
                            {progress.toFixed(2).replace('.', ',')}%
                        </span>
                    </div>

                    <div className="relative h-8 md:h-12 flex rounded-full bg-gray-200 w-full">
                        <div
                            style={{ width: `${progressBarValue}%` }}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gradient-to-r from-red-600 to-orange-600 rounded-full transition-all duration-500"
                        ></div>
                    </div>
<br/>
                    {/* Stylized button with redirection */}
                    <div className="mb-2 flex justify-center hover-red-600">
                        <button
                            onClick={handlePreorderClick}
                            className="font-bold font-binaria text-white bg-black py-2 px-6 md:py-3 md:px-8 rounded-lg shadow-md hover-red-600 transition duration-300 ease-in-out"
                        >
                            Précommande ta paire
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HeroPresentation;

import React from 'react';

interface FormData {
    name: string;
    brand: string;
    price: string | number;
    description: string;
    details: string;
    likes_current: number;
    likes_target: number;
    sizes_type: 'unique' | 'clothing' | 'shoes';
    sizes_stock: { [key: string]: number };
    image_1: string;
    image_2: string;
    image_3: string;
    image_4: string;
    image_5: string;
    hero_title?: string;
    hero_description?: string;
    hero_image?: string;
}

interface ProductEditFormProps {
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
    handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
    handleSizesTypeChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    handleSizesStockChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const sizeOptions = {
    unique: ['Taille unique'],
    clothing: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
    shoes: ['36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46']
};

const ProductEditForm: React.FC<ProductEditFormProps> = ({
                                                             formData,
                                                             setFormData,
                                                             handleSubmit,
                                                             handleInputChange,
                                                             handleSizesTypeChange,
                                                             handleSizesStockChange
                                                         }) => {
    return (
        <form
            onSubmit={handleSubmit}
            className="max-w-4xl mx-auto p-8 bg-white border border-gray-400 rounded-lg shadow-lg space-y-8"
        >
            {/* Basic Information */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <label htmlFor="name" className="block text-lg font-medium text-gray-800">Nom</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                        placeholder="Nom du produit"
                    />
                </div>
                <div>
                    <label htmlFor="brand" className="block text-lg font-medium text-gray-800">Marque</label>
                    <input
                        type="text"
                        name="brand"
                        value={formData.brand}
                        onChange={handleInputChange}
                        required
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                        placeholder="Marque du produit"
                    />
                </div>
                <div>
                    <label htmlFor="price" className="block text-lg font-medium text-gray-800">Prix</label>
                    <input
                        type="number"
                        name="price"
                        value={formData.price}
                        onChange={handleInputChange}
                        required
                        step="0.01"
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                        placeholder="Prix"
                    />
                </div>
                <div>
                    <label htmlFor="sizes_type" className="block text-lg font-medium text-gray-800">Type de tailles</label>
                    <select
                        name="sizes_type"
                        value={formData.sizes_type}
                        onChange={handleSizesTypeChange}
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                    >
                        <option value="unique">Taille unique</option>
                        <option value="clothing">Vêtements</option>
                        <option value="shoes">Chaussures</option>
                    </select>
                </div>
            </div>

            {/* Product Description and Details */}
            <div>
                <label htmlFor="description" className="block text-lg font-medium text-gray-800">Description</label>
                <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    rows={4}
                    className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                    placeholder="Description du produit"
                />
            </div>

            <div>
                <label htmlFor="details" className="block text-lg font-medium text-gray-800">Détails</label>
                <textarea
                    name="details"
                    value={formData.details}
                    onChange={handleInputChange}
                    rows={4}
                    className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                    placeholder="Détails du produit"
                />
            </div>

            {/* Likes Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div>
                    <label htmlFor="likes_current" className="block text-lg font-medium text-gray-800">Likes actuels</label>
                    <input
                        type="number"
                        name="likes_current"
                        value={formData.likes_current}
                        onChange={handleInputChange}
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                        placeholder="Nombre actuel de likes"
                    />
                </div>
                <div>
                    <label htmlFor="likes_target" className="block text-lg font-medium text-gray-800">Objectif de likes</label>
                    <input
                        type="number"
                        name="likes_target"
                        value={formData.likes_target}
                        onChange={handleInputChange}
                        required
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                        placeholder="Objectif de likes"
                    />
                </div>
            </div>

            {/* Stock per Size */}
            <div>
                <label className="block text-lg font-medium text-gray-800">Stock par taille</label>
                <div className="grid grid-cols-3 gap-6">
                    {sizeOptions[formData.sizes_type].map((size) => (
                        <div key={size}>
                            <label htmlFor={size} className="block text-sm font-medium text-gray-700">{size}</label>
                            <input
                                type="number"
                                name={size}
                                value={formData.sizes_stock[size] || ''}
                                onChange={handleSizesStockChange}
                                className="mt-2 block w-full px-3 py-2 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                                placeholder={`Stock pour ${size}`}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Images */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {[1, 2, 3, 4, 5].map((num) => (
                    <div key={num}>
                        <label htmlFor={`image_${num}`} className="block text-lg font-medium text-gray-800">Image {num}</label>
                        <input
                            type="text"
                            name={`image_${num}`}
                            value={String(formData[`image_${num}` as keyof FormData] || '')}
                            onChange={handleInputChange}
                            className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                            placeholder={`Lien de l'image ${num}`}
                        />
                    </div>
                ))}
            </div>

            {/* Hero Section */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div>
                    <label htmlFor="hero_title" className="block text-lg font-medium text-gray-800">Titre du produit mis en avant</label>
                    <input
                        type="text"
                        name="hero_title"
                        value={formData.hero_title || ''}
                        onChange={handleInputChange}
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                        placeholder="Titre du produit mis en avant"
                    />
                </div>
                <div>
                    <label htmlFor="hero_description" className="block text-lg font-medium text-gray-800">Description du produit mis en avant</label>
                    <textarea
                        name="hero_description"
                        value={formData.hero_description || ''}
                        onChange={handleInputChange}
                        rows={3}
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                        placeholder="Description du produit mis en avant"
                    />
                </div>
                <div>
                    <label htmlFor="hero_image" className="block text-lg font-medium text-gray-800">Lien de l'image du produit mis en avant</label>
                    <input
                        type="text"
                        name="hero_image"
                        value={formData.hero_image || ''}
                        onChange={handleInputChange}
                        className="mt-2 block w-full px-4 py-3 text-base rounded-lg border-gray-300 bg-gray-100 shadow-sm"
                        placeholder="Lien de l'image"
                    />
                </div>
            </div>

            {/* Submit Button */}
            <button
                type="submit"
                className="w-full px-6 py-3 text-lg bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-4 focus:ring-indigo-300 transition"
            >
                Enregistrer les modifications
            </button>
        </form>
    );
};

export default ProductEditForm;

import React, { useEffect, useState } from 'react';
import { useCart } from '../context/CartContext'; // Utilise le contexte du panier
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

type CartItem = {
    id: number;
    title: string;
    price: number;
    image: string;
    size: string; // Ajout de la taille ici
    quantity: number;
};

const SuccessPage: React.FC = () => {
    const { cartItems, clearCart } = useCart(); // Utilisation du contexte pour récupérer et vider le panier
    const [orderItems, setOrderItems] = useState<CartItem[]>(cartItems);
    const [hasOrderBeenCreated, setHasOrderBeenCreated] = useState(false); // Nouvel état pour éviter les appels répétés
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log('SuccessPage initialisée.');
        console.log('Articles dans le panier:', cartItems);

        // Récupérer l'ID de la session Stripe depuis l'URL
        const queryParams = new URLSearchParams(location.search);
        const sessionId = queryParams.get('session_id');

        console.log('Session ID Stripe récupérée :', sessionId);

        if (sessionId && !hasOrderBeenCreated) {
            // Si l'ID de session Stripe est présent et que la commande n'a pas encore été créée
            fetchStripeSession(sessionId);
        }

        if (cartItems.length > 0 && !hasOrderBeenCreated) {
            // Stocker les articles dans l'état avant de vider le panier
            setOrderItems(cartItems);
            clearCart(); // Vider le panier après validation de la commande
        }
    }, [cartItems, clearCart, location, hasOrderBeenCreated]); // Ajout de `hasOrderBeenCreated` dans les dépendances

    // Fonction pour récupérer les informations de la session Stripe
    const fetchStripeSession = async (sessionId: string) => {
        try {
            console.log('Envoi de la requête pour récupérer la session Stripe');
            const response = await axios.post('http://localhost:3001/api/stripe-session', {
                session_id: sessionId
            });
            console.log('Détails de la session Stripe récupérés :', response.data);
            createOrder(response.data); // Créer la commande après récupération des détails Stripe
        } catch (error) {
            console.error('Erreur lors de la récupération de la session Stripe :', error);
            // Si la récupération de Stripe échoue, on peut quand même créer la commande sans les détails Stripe
            createOrder({}); // Crée la commande avec des valeurs par défaut si Stripe échoue
        }
    };

    // Fonction pour créer une commande dans la base de données
    const createOrder = async (stripeData: any) => {
        if (hasOrderBeenCreated) {
            console.log('Commande déjà créée.');
            return; // Sortir si la commande a déjà été créée
        }

        try {
            // Récupérer le token JWT depuis le localStorage
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Erreur : token non trouvé dans le localStorage');
                return;
            }

            // Décoder le token JWT pour récupérer le user_id
            const decodedToken = jwtDecode<{ userId: string }>(token);
            const user_id = decodedToken.userId;
            if (!user_id) {
                console.error('Erreur : user_id non trouvé dans le token');
                return;
            }

            const address_id = 1; // Pour l'instant, on utilise une adresse fixe

            // Débogage des informations à envoyer
            console.log('Création de la commande avec les données :');
            console.log('User ID:', user_id);
            console.log('Address ID:', address_id);
            console.log('Articles du panier :', orderItems);
            console.log('Prix total :', totalPrice);
            console.log('Stripe Data :', stripeData);

            // Envoi de la requête POST pour créer la commande
            const response = await axios.post('http://localhost:3001/api/orders', {
                user_id,
                address_id,
                cartItems: orderItems,
                total_price: totalPrice,
                stripe_payment_intent: stripeData.payment_intent || 'n/a' // Si aucune donnée Stripe, on met 'n/a'
            });

            console.log('Commande enregistrée avec succès :', response.data);

            // Empêcher la création multiple de la commande
            setHasOrderBeenCreated(true);
        } catch (error) {
            console.error('Erreur lors de la création de la commande :', error);
        }
    };

    // Calcul du prix total des articles dans le panier
    const totalPrice = orderItems.reduce((total, item) => total + item.price * item.quantity, 0);

    return (
        <div>
            <Header /> {/* En-tête */}
            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold text-center mb-8">
                    Merci pour votre commande !
                </h1>
                <p className="text-center text-lg mb-6">
                    Votre commande a bien été prise en compte. Voici un récapitulatif :
                </p>

                {/* Récapitulatif des articles */}
                <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
                    <h2 className="text-2xl font-semibold mb-4">Vos Articles</h2>
                    <ul className="divide-y divide-gray-200">
                        {orderItems.map((item) => (
                            <li key={item.id} className="flex items-center justify-between py-4">
                                <div className="flex items-center">
                                    <img src={item.image} alt={item.title} className="w-16 h-16 object-cover rounded-md" />
                                    <div className="ml-4">
                                        <h3 className="font-semibold text-lg">{item.title}</h3>
                                        <p className="text-sm text-gray-600">Taille : {item.size}</p> {/* Taille ajoutée ici */}
                                        <p className="text-sm text-gray-600">Quantité : {item.quantity}</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="font-semibold text-lg">{item.price.toFixed(2)} €</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-4">
                        <h3 className="text-xl font-semibold">
                            Total de la commande : {totalPrice.toFixed(2)} €
                        </h3>
                    </div>
                </div>

                {/* Bouton pour retourner à la boutique */}
                <div className="text-center">
                    <button onClick={() => navigate('/')} className="px-6 py-3 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-300">
                        Retour à la boutique
                    </button>
                </div>
            </div>
            <Footer /> {/* Pied de page */}
        </div>
    );
};

export default SuccessPage;
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

export default function ModalVote({ isOpen, onClose, children }: ModalProps) {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-15 backdrop-blur-sm">
            <div
                className="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full"
                onClick={(e) => e.stopPropagation()} // Empêche la fermeture en cliquant à l'intérieur du modal
            >
                {children}
            </div>
        </div>,
        document.body
    );
}
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Range, getTrackBackground } from 'react-range';
import UserChart from './UserChart'; // Importation du composant

interface User {
    id: number;
    title: 'M' | 'Mme';
    first_name: string;
    last_name: string;
    email: string;
    date_of_birth: string;
    subscribeNewsletter: boolean;
}

const UserList = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [isFiltersVisible, setIsFiltersVisible] = useState(false);
    const [ageRange, setAgeRange] = useState<[number, number]>([18, 60]);
    const [selectedTitle, setSelectedTitle] = useState<string>('');
    const [isNewsletterSubscriber, setIsNewsletterSubscriber] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>(''); // Recherche par nom/prénom

    const MIN_AGE = 18;
    const MAX_AGE = 100;

    const [selectedDataType, setSelectedDataType] = useState<string>('age'); // Pour choisir le type de données à afficher

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('/api/users');
                setUsers(response.data.users);
                setFilteredUsers(response.data.users);
            } catch (error) {
                setError('Erreur lors de la récupération des utilisateurs');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const calculateAge = (dateOfBirth: string) => {
        return dayjs().diff(dayjs(dateOfBirth), 'year');
    };

    const applyFilters = () => {
        let filtered = users;

        // Filtrer par âge
        filtered = filtered.filter(user => {
            const age = calculateAge(user.date_of_birth);
            return age >= ageRange[0] && age <= ageRange[1];
        });

        // Filtrer par sexe
        if (selectedTitle) {
            filtered = filtered.filter(user => user.title === selectedTitle);
        }

        // Filtrer par abonnement à la newsletter
        if (isNewsletterSubscriber !== '') {
            filtered = filtered.filter(user => user.subscribeNewsletter === (isNewsletterSubscriber === 'true'));
        }

        // Recherche par nom ou prénom
        if (searchTerm) {
            filtered = filtered.filter(user =>
                user.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredUsers(filtered);
    };

    const resetFilters = () => {
        setAgeRange([18, 60]);
        setSelectedTitle('');
        setIsNewsletterSubscriber('');
        setSearchTerm(''); // Réinitialiser la recherche
        setFilteredUsers(users);
    };

    const generateChartData = (): { name: string; y: number }[] => {
        if (selectedDataType === 'age') {
            const ageGroups: { [age: string]: number } = {};
            for (let age = MIN_AGE; age <= MAX_AGE; age++) {
                ageGroups[age] = 0;
            }
            filteredUsers.forEach(user => {
                const age = calculateAge(user.date_of_birth);
                if (age >= ageRange[0] && age <= ageRange[1]) {
                    ageGroups[age]++;
                }
            });
            return Object.keys(ageGroups).map(age => ({
                name: age,
                y: ageGroups[age],
            }));
        }

        if (selectedDataType === 'gender') {
            const genderGroups: { M: number; Mme: number } = { M: 0, Mme: 0 };
            filteredUsers.forEach(user => {
                genderGroups[user.title]++;
            });
            return Object.keys(genderGroups).map(key => ({
                name: key === 'M' ? 'Homme' : 'Femme',
                y: genderGroups[key as keyof typeof genderGroups],
            }));
        }

        if (selectedDataType === 'newsletter') {
            const newsletterGroups: { Abonné: number; 'Non Abonné': number } = { Abonné: 0, 'Non Abonné': 0 };
            filteredUsers.forEach(user => {
                if (user.subscribeNewsletter) {
                    newsletterGroups['Abonné']++;
                } else {
                    newsletterGroups['Non Abonné']++;
                }
            });
            return Object.keys(newsletterGroups).map(key => ({
                name: key,
                y: newsletterGroups[key as keyof typeof newsletterGroups],
            }));
        }

        return [];
    };

    if (loading) return <p>Chargement...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h1 className="text-4xl font-bold">Liste des utilisateurs</h1>
            <br />
            {/* Sélecteur pour le type de données à afficher */}
            <div className="mb-6">
                <label className="block text-gray-700 font-semibold mb-2">Type de données à afficher</label>
                <select
                    className="border border-gray-300 rounded-lg py-2 px-4 w-full"
                    value={selectedDataType}
                    onChange={(e) => setSelectedDataType(e.target.value)}
                >
                    <option value="age">Âge exact</option>
                    <option value="gender">Sexe</option>
                    <option value="newsletter">Newsletter</option>
                </select>
            </div>

            {/* Intégration du composant UserChart */}
            <UserChart data={generateChartData()} selectedDataType={selectedDataType} />
            <br />

            {/* Recherche par nom ou prénom */}
            <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">Recherche par nom ou prénom</label>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="border border-gray-300 rounded-lg py-2 px-4 w-full"
                    placeholder="Recherche par nom ou prénom..."
                />
            </div>

            {/* Filtres */}
            {isFiltersVisible && (
                <div className="mb-6 bg-gray-100 p-4 rounded-lg">
                    <h2 className="text-xl font-semibold mb-4">Filtres</h2>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Fourchette d'âge : {ageRange[0]} - {ageRange[1]} ans</label>
                        <Range
                            values={ageRange}
                            step={1}
                            min={MIN_AGE}
                            max={MAX_AGE}
                            onChange={(values) => setAgeRange([values[0], values[1]])}
                            renderTrack={({ props, children }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: '6px',
                                        width: '100%',
                                        background: getTrackBackground({
                                            values: ageRange,
                                            colors: ['#ccc', '#548BF4', '#ccc'],
                                            min: MIN_AGE,
                                            max: MAX_AGE
                                        })
                                    }}
                                >
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props }) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        height: '20px',
                                        width: '20px',
                                        backgroundColor: '#548BF4',
                                        borderRadius: '50%'
                                    }}
                                />
                            )}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Sexe</label>
                        <select
                            className="border border-gray-300 rounded-lg py-2 px-4 w-full"
                            value={selectedTitle}
                            onChange={(e) => setSelectedTitle(e.target.value)}
                        >
                            <option value="">Tous</option>
                            <option value="M">Homme</option>
                            <option value="Mme">Femme</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Abonnement à la newsletter</label>
                        <select
                            className="border border-gray-300 rounded-lg py-2 px-4 w-full"
                            value={isNewsletterSubscriber}
                            onChange={(e) => setIsNewsletterSubscriber(e.target.value)}
                        >
                            <option value="">Tous</option>
                            <option value="true">Abonné</option>
                            <option value="false">Non abonné</option>
                        </select>
                    </div>

                    <button
                        onClick={applyFilters}
                        className="bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded mr-2"
                    >
                        Appliquer les filtres
                    </button>

                    <button
                        onClick={resetFilters}
                        className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
                    >
                        Réinitialiser
                    </button>
                </div>
            )}

            {/* Bouton pour afficher/masquer les filtres */}
            <button
                onClick={() => setIsFiltersVisible(!isFiltersVisible)}
                className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded mb-6"
            >
                {isFiltersVisible ? 'Masquer les filtres' : 'Afficher les filtres'}
            </button>

            {/* Titre pour la section des utilisateurs */}
            <h2 className="text-xl font-bold mb-4">Liste des utilisateurs</h2>

            {/* Affichage du nombre d'utilisateurs */}
            <p className="mb-4">Nombre total d'utilisateurs : {users.length}</p>
            <p className="mb-4">Nombre d'utilisateurs correspondant aux filtres : {filteredUsers.length}</p>

            {/* Tableau en bas */}
            <table className="min-w-full bg-white shadow-lg rounded-lg overflow-hidden">
                <thead>
                <tr className="bg-gray-100">
                    <th className="px-4 py-2">ID</th>
                    <th className="px-4 py-2">Titre</th>
                    <th className="px-4 py-2">Prénom</th>
                    <th className="px-4 py-2">Nom</th>
                    <th className="px-4 py-2">Email</th>
                    <th className="px-4 py-2">Âge</th>
                    <th className="px-4 py-2">Newsletter</th>
                </tr>
                </thead>
                <tbody>
                {filteredUsers.map((user) => (
                    <tr key={user.id} className="border-t">
                        <td className="border px-4 py-2 text-center">{user.id}</td>
                        <td className="border px-4 py-2 text-center">{user.title}</td>
                        <td className="border px-4 py-2 text-center">{user.first_name}</td>
                        <td className="border px-4 py-2 text-center">{user.last_name}</td>
                        <td className="border px-4 py-2 text-center">{user.email}</td>
                        <td className="border px-4 py-2 text-center">{calculateAge(user.date_of_birth)} ans</td>
                        <td className="border px-4 py-2 text-center">
                            {user.subscribeNewsletter ? 'Oui' : 'Non'}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserList;
import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const CancelPage = () => {
    return (
        <div className="min-h-screen flex flex-col">
            <Header />

            <main className="flex-grow container mx-auto px-4 py-16 text-center">
                <h1 className="text-3xl font-bold text-red-600 mb-6">
                    Votre commande n'a pas abouti !
                </h1>
                <p className="text-lg text-gray-700 mb-8">
                    Il semble que vous ayez annulé le processus de paiement. Si vous avez besoin d'aide ou souhaitez retenter, cliquez ci-dessous pour revenir à la page d'accueil.
                </p>

                <Link to="/" className="px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-all">
                    Retour à la page d'accueil
                </Link>
            </main>

            <Footer />
        </div>
    );
};

export default CancelPage;
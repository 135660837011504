import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LocationLogo from '../assets/location.svg';  // Import du logo de localisation
import MailLogo from '../assets/email.svg';         // Import du logo d'email

const ContactPage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                {/* Formulaire de contact */}
                <section className="w-3/4 mx-auto my-16 p-8 bg-gray-100 rounded-lg shadow-lg">
                    <h2 className="font-binaria text-4xl font-bold mb-8 text-left">CONTACTEZ-NOUS</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {/* Informations personnelles */}
                        <div className="flex flex-col">
                            <label htmlFor="name" className="font-binaria text-lg font-semibold mb-2">
                                Nom
                            </label>
                            <input
                                type="text"
                                id="name"
                                placeholder="Votre nom"
                                className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="email" className="font-binaria text-lg font-semibold mb-2">
                                Email
                            </label>
                            <input
                                type="email"
                                id="email"
                                placeholder="Votre email"
                                className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* Sujet */}
                        <div className="flex flex-col md:col-span-2">
                            <label htmlFor="subject" className="font-binaria text-lg font-semibold mb-2">
                                Sujet
                            </label>
                            <input
                                type="text"
                                id="subject"
                                placeholder="Sujet de votre message"
                                className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* Message */}
                        <div className="flex flex-col md:col-span-2">
                            <label htmlFor="message" className="font-binaria text-lg font-semibold mb-2">
                                Message
                            </label>
                            <textarea
                                id="message"
                                rows={5}
                                placeholder="Écrivez votre message"
                                className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                            />
                        </div>

                        {/* Bouton de soumission */}
                        <div className="md:col-span-2 flex justify-center">
                            <button
                                className="font-binaria bg-orange-500 text-white px-8 py-3 rounded-md font-semibold hover:bg-orange-600 transition-all">
                                Envoyer
                            </button>
                        </div>
                    </div>
                </section>

                {/* Informations de contact (adresse et email) */}
                <section className="w-3/4 mx-auto mt-8 p-8 bg-white rounded-lg shadow-lg">
                    <h3 className="font-binaria text-2xl font-bold mb-6">INFORMATIONS</h3>

                    {/* Adresse */}
                    <div className="flex items-start mb-6">

                        <div className="text-lg">
                            <p>44 avenue Barbès</p>
                            <p>93340 Villepinte</p>
                            <p>France</p>
                        </div>
                    </div>

                    <hr className="my-4 border-gray-300" />

                    {/* Email */}
                    <div className="flex items-start">

                        <div className="text-lg">
                            <p>Envoyez-nous un e-mail :</p>
                            <p className="text-orange-500">no-reply@add-last.com</p>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

export default ContactPage;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo_blanc_add_last_transparent.png';
import { ShoppingCart, User, Menu, X } from 'lucide-react';
import CartSidebar from '../components/CartSideBar';

const NAV_LINKS = [
    { text: 'HOME', href: '/' },
    { text: 'ABOUT', href: '/about' },
    { text: 'PRODUCTS', href: '/allproductspage' },
];

const HomePageHeader = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isCartOpen, setCartOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false); // Pour gérer l'effet sticky et la transparence
    const navigate = useNavigate();

    // Gestion du scroll pour ajouter l'effet de transparence et de flou
    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => setMenuOpen(!isMenuOpen);
    const toggleCart = () => setCartOpen(!isCartOpen);

    const handleUserClick = () => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/client');
        } else {
            navigate('/auth');
        }
    };

    return (
        <>
            <header
                className={`fixed top-0 left-0 right-0 w-full transition-all duration-300 ease-in-out z-50 font-binaria h-16 md:h-20 ${
                    isSticky ? 'bg-black bg-opacity-70 backdrop-blur-md' : 'bg-black'
                }`} // Hauteur toujours compacte
            >
                <div className="container mx-auto px-4 h-full flex justify-between items-center">
                    {/* Logo et menu burger pour mobile */}
                    <div className="flex items-center">
                        <Link to="/" className="flex items-center">
                            <img
                                src={logo}
                                alt="Logo"
                                className="h-8" // Taille fixe du logo, toujours compact
                            />
                        </Link>
                        <button onClick={toggleMenu} className="ml-4 md:hidden text-white focus:outline-none">
                            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>

                    {/* Navigation pour desktop */}
                    <nav className="hidden md:flex items-center space-x-8">
                        {NAV_LINKS.map((link, index) => (
                            <Link
                                key={index}
                                to={link.href}
                                className="text-white hover:text-gray-300 transition-colors duration-200 text-sm lg:text-base"
                            >
                                {link.text}
                            </Link>
                        ))}
                    </nav>

                    {/* Icônes (panier et profil) */}
                    <div className="flex items-center space-x-4 md:space-x-8">
                        {/* Icône du panier */}
                        <button onClick={toggleCart} className="text-white hover:text-gray-300 focus:outline-none z-50">
                            <ShoppingCart size={24} />
                        </button>
                        <button onClick={handleUserClick} className="text-white hover:text-gray-300 focus:outline-none">
                            <User size={24} />
                        </button>
                    </div>
                </div>

                {/* Menu mobile */}
                {isMenuOpen && (
                    <nav className="md:hidden bg-black text-white py-4 px-4 space-y-4">
                        {NAV_LINKS.map((link, index) => (
                            <Link
                                key={index}
                                to={link.href}
                                className="block text-white hover:text-gray-300 transition-colors duration-200 text-lg"
                            >
                                {link.text}
                            </Link>
                        ))}
                    </nav>
                )}
            </header>

            {/* CartSidebar */}
            <CartSidebar isOpen={isCartOpen} toggleCart={toggleCart} />

            {/* Ajout du padding-top pour compenser la hauteur du header */}
            <div className="pt-16 md:pt-20">
                {/* Ton contenu ici */}
            </div>
        </>
    );
};

export default HomePageHeader;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import BottomNavigation from "../../components/BottomNavigation";


const ListsPage = () => {
    const [lists, setLists] = useState<any[]>([]);
const [errorMessage, setErrorMessage] = useState('');

useEffect(() => {
    const fetchLists = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setErrorMessage('Token non trouvé, veuillez vous reconnecter.');
                return;
            }

            const response = await axios.get('/api/lists', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setLists(response.data.lists || []);
        } catch (error) {
            setErrorMessage('Erreur lors de la récupération des listes.');
            console.error(error);
        }
    };

    fetchLists();
}, []);

return (
    <div className="flex flex-col min-h-screen font-binaria">
        <Header />
        <main className="flex-grow px-8 py-8">
            {/* Titre de la page */}
            <h1 className="text-4xl font-bold text-center mb-8">Mes listes</h1>

            {/* Bouton pour créer une nouvelle liste */}
            <div className="flex justify-end mb-4">
                <Link to="/create-list"
                      className="flex items-center text-blue-500 hover:text-blue-700 font-semibold text-sm">

                    Créer une nouvelle liste
                </Link>
            </div>

            {/* Liste des listes */}
            <div className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto">
                {lists.length === 0 ? (
                    <div className="text-gray-600">
                        <p>Ma liste d'envies (0)</p>
                    </div>
                ) : (
                    lists.map((list, index) => (
                        <div key={index}
                             className="flex justify-between items-center border border-gray-300 p-4 rounded-md mb-4">
                            <p className="text-lg font-semibold">{list.name}</p>

                        </div>
                    ))
                )}
            </div>
            <br/><br/>
            <BottomNavigation/>
        </main>
        <Footer/>
    </div>
);
};

export default ListsPage;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import BottomNavigation from "../../components/BottomNavigation";

const CreditsPage = () => {
    const [credits, setCredits] = useState<any[]>([]);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchCredits = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setErrorMessage('Token non trouvé, veuillez vous reconnecter.');
                    return;
                }

                const response = await axios.get('/api/credits', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                setCredits(response.data.credits || []);
            } catch (error) {
                setErrorMessage('Erreur lors de la récupération des avoirs.');
                console.error(error);
            }
        };

        fetchCredits();
    }, []);

    return (
        <div className="flex flex-col min-h-screen font-binaria">
            <Header />
            <main className="flex-grow px-8 py-8">
                {/* Titre de la page */}
                <h1 className="text-4xl font-bold text-center mb-8">Avoirs</h1>

                {/* Message en cas d'absence d'avoirs */}
                <div className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto">
                    {credits.length === 0 ? (
                        <div
                            className="bg-orange-100 border border-orange-400 text-orange-700 px-4 py-3 rounded relative mb-4">
                            <strong className="font-bold">Vous n'avez reçu aucun avoir</strong>
                        </div>
                    ) : (
                        <div>
                            {/* Afficher la liste des avoirs ici */}
                        </div>
                    )}

                    <p className="text-gray-600 text-center">
                        Liste des avoirs reçus suite à des annulations de commandes.
                    </p>
                </div>

                {/* Navigation en bas de la page */}
                <br/><br/>
                <BottomNavigation/>
            </main>

            <Footer />
        </div>
    );
};

export default CreditsPage;

import React from 'react';
import { Link } from 'react-router-dom';
import InstaLogo from '../assets/Instagram.png';
import FacebookLogo from '../assets/Facebook.png';
import logo from '../assets/logo_blanc_add_last_transparent.png';

const Footer = () => {
    return (
        <footer className="w-full bg-black text-white py-8 font-binaria">
            <div className="w-4/5 mx-auto bg-white text-black p-4 flex flex-col lg:flex-row justify-between items-center rounded-lg">
                <h3 className=" text-lg mb-4 lg:mb-0 lg:mr-4 font-semibold">
                    REJOINS LA NEWSLETTER
                </h3>
                <div className="flex flex-1 lg:flex-row flex-col lg:space-x-4 w-full">
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="px-4 py-2 mb-4 lg:mb-0 lg:w-full rounded-md text-black border border-gray-300 focus:outline-none focus:ring-2 focus:ring-orange-500"
                    />
                    <button className="bg-[rgb(226,85,53)] text-white px-6 py-2 rounded-md hover:bg-orange-600 w-full lg:w-auto transition-all hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-orange-500">
                        Rejoindre
                    </button>
                </div>
            </div>

            {/* Footer Content */}
            <div className="w-4/5 mx-auto mt-8 grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
                <div className="flex flex-col items-start w-full">
                    <div className="flex flex-col items-start space-y-4">
                        <img
                            src={logo}
                            alt="Add-Last Logo"
                            className="w-48 h-auto mb-2 ml-2"
                        />
                        <div className="flex space-x-4">
                            <a href="https://www.instagram.com/add_last" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={InstaLogo}
                                    alt="Suivez-nous sur Instagram"
                                    className="w-8 h-8 hover:opacity-80 transition-opacity ml-4"
                                />
                            </a>
                            <a href="https://www.facebook.com/addlastsneakers" target="_blank" rel="noopener noreferrer">
                                <img
                                    src={FacebookLogo}
                                    alt="Suivez-nous sur Facebook"
                                    className="w-8 h-8 hover:opacity-80 transition-opacity"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                {/* A PROPOS Section */}
                <div className="text-left flex flex-col w-full">
                    <h4 className="font-bold text-lg mb-4 ml-4 font-binaria ">A PROPOS</h4>
                    <ul className="space-y-2 ml-4 font-binaria font-bold">
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/faq">FAQ</Link>
                        </li>
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/partnership">Partenariat</Link>
                        </li>
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/tracking">Suivi de Colis</Link>
                        </li>
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/contact">Nous contacter</Link>
                        </li>
                    </ul>
                </div>

                {/* Réglementation Section */}
                <div className="text-left flex flex-col w-full">
                    <h4 className="font-binaria font-bold text-lg mb-4 ml-4">Réglementation</h4>
                    <ul className="font-bold font-binaria space-y-2 ml-4">
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/legal">Mentions Légales</Link>
                        </li>
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/delivery-policy">Politique de Livraison</Link>
                        </li>
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/privacy-policy">Politique de confidentialité</Link>
                        </li>
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/refund-policy">Politique de remboursement</Link>
                        </li>
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/terms-of-service">Conditions Générales de Ventes</Link>
                        </li>
                        <li className="hover:text-[rgb(226,85,53)] transition-colors cursor-pointer">
                            <Link to="/terms-of-use">Conditions d'utilisation</Link>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Payment Methods Section */}
            <div className="w-4/5 mx-auto mt-8 flex flex-col justify-center items-center">
                <h4 className="font-binaria font-bold text-lg mb-4">Moyens de paiement</h4>
                <div className="flex space-x-4 items-center">
                    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="24" rx="4" fill="#000"></rect>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M10.75 15.858H8.69L7.146 9.792c-.074-.279-.23-.526-.458-.642A6.573 6.573 0 0 0 4.8 8.508v-.233h3.318c.458 0 .801.35.859.758l.8 4.375 2.06-5.133h2.002l-3.089 7.583Zm4.234 0h-1.945l1.602-7.584h1.945l-1.602 7.584Zm4.118-5.483c.058-.408.401-.642.802-.642a3.534 3.534 0 0 1 1.888.35l.343-1.633a4.801 4.801 0 0 0-1.773-.35c-1.888 0-3.262 1.05-3.262 2.508 0 1.109.973 1.691 1.66 2.042.743.35 1.03.583.972.933 0 .525-.572.758-1.144.758a4.789 4.789 0 0 1-2.002-.467l-.344 1.634c.687.291 1.43.409 2.117.409 2.117.057 3.433-.992 3.433-2.567 0-1.984-2.69-2.1-2.69-2.975Zm9.498 5.483-1.545-7.584h-1.659a.863.863 0 0 0-.801.584l-2.86 7h2.002l.4-1.108h2.46l.23 1.108H28.6Zm-2.918-5.541.572 2.858h-1.602l1.03-2.858Z"
                              fill="#fff"></path>
                    </svg>
                    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="24" rx="4" fill="#000"></rect>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M9.45 8.343c-.286.352-.743.63-1.2.59-.056-.476.167-.981.429-1.294.285-.361.784-.62 1.189-.639.047.496-.138.981-.419 1.343Zm.413.684c-.402-.024-.77.126-1.066.248-.19.078-.352.144-.475.144-.138 0-.306-.07-.495-.148-.248-.103-.53-.22-.827-.214-.68.01-1.313.411-1.66 1.05-.714 1.28-.186 3.173.504 4.213.338.516.742 1.08 1.275 1.061.234-.01.402-.084.577-.16.2-.09.41-.182.735-.182.315 0 .514.09.706.176.182.082.357.161.617.156.551-.01.899-.515 1.236-1.03.365-.554.525-1.094.55-1.176l.002-.01-.013-.006a1.853 1.853 0 0 1-1.062-1.693c-.009-1 .739-1.507.856-1.586l.014-.01c-.475-.734-1.217-.813-1.474-.833Zm3.82 6.29V7.59h2.782c1.436 0 2.44 1.03 2.44 2.537s-1.023 2.548-2.478 2.548h-1.594v2.642h-1.15Zm1.15-6.716h1.327c1 0 1.57.555 1.57 1.531 0 .977-.57 1.537-1.574 1.537h-1.323V8.6Zm7.872 5.789c-.304.604-.975.986-1.698.986-1.07 0-1.817-.664-1.817-1.665 0-.991.723-1.561 2.06-1.646l1.436-.089v-.426c0-.63-.395-.972-1.099-.972-.58 0-1.003.313-1.089.789h-1.037c.034-1.002.937-1.73 2.16-1.73 1.317 0 2.174.718 2.174 1.834v3.846h-1.066v-.927h-.024Zm-1.389.07c-.613 0-1.003-.308-1.003-.779 0-.486.376-.768 1.094-.813l1.28-.084v.436c0 .724-.59 1.24-1.37 1.24Zm6.012 1.159c-.461 1.353-.989 1.8-2.111 1.8-.086 0-.371-.01-.438-.03v-.927c.071.01.247.02.338.02.509 0 .794-.224.97-.803l.105-.343-1.95-5.625h1.203l1.355 4.565h.024L28.18 9.71h1.17l-2.022 5.908Z"
                              fill="#fff"></path>
                    </svg>
                    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="24" rx="4" fill="#000"></rect>
                        <path
                            d="M14.166 13.403c-.123.675-.61 3.822-.755 4.711-.011.064-.036.088-.106.088h-2.623a.427.427 0 0 1-.425-.489l2.06-13.075a.714.714 0 0 1 .704-.594c5.354 0 5.804-.13 7.172.401 2.112.82 2.306 2.795 1.546 4.933-.756 2.201-2.548 3.147-4.925 3.175-1.525.024-2.443-.247-2.647.85Zm8.639-5.059c-.064-.046-.088-.064-.106.046-.07.4-.18.79-.31 1.18-1.402 4.002-5.29 3.654-7.189 3.654-.214 0-.355.116-.383.33-.794 4.936-.953 5.966-.953 5.966-.035.25.124.453.373.453h2.232a.631.631 0 0 0 .612-.523c.025-.19-.038.214.507-3.21.162-.774.502-.693 1.03-.693 2.496 0 4.443-1.012 5.023-3.948.229-1.223.162-2.51-.836-3.256v.001Z"
                            fill="#fff"></path>
                    </svg>
                    <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="34" height="24" rx="4" fill="#000"></rect>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.179 16.83a6.802 6.802 0 0 1-4.398 1.6c-3.745 0-6.781-3-6.781-6.7s3.036-6.7 6.78-6.7c1.679 0 3.215.603 4.399 1.6a6.802 6.802 0 0 1 4.398-1.6c3.745 0 6.781 3 6.781 6.7s-3.036 6.7-6.78 6.7a6.802 6.802 0 0 1-4.399-1.6Z"
                              fill="#fff"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.179 16.83a6.653 6.653 0 0 0 2.382-5.1c0-2.042-.924-3.87-2.382-5.1a6.802 6.802 0 0 1 4.398-1.6c3.745 0 6.78 3 6.78 6.7s-3.035 6.7-6.78 6.7a6.802 6.802 0 0 1-4.398-1.6Z"
                              fill="#fff"></path>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M17.18 16.83a6.653 6.653 0 0 0 2.382-5.1c0-2.042-.925-3.87-2.383-5.1a6.653 6.653 0 0 0-2.382 5.1c0 2.042.924 3.87 2.382 5.1Z"
                              fill="#fff"></path>
                        <path
                            d="m17.18 16.83-.162.19.161.137.161-.136-.16-.191Zm0-10.2.16-.19-.16-.136-.162.136.161.19Zm2.132 5.1a6.403 6.403 0 0 1-2.294 4.909l.322.382a6.903 6.903 0 0 0 2.472-5.29h-.5Zm-2.294-4.908a6.403 6.403 0 0 1 2.294 4.908h.5c0-2.119-.96-4.016-2.472-5.29l-.322.382Zm-1.971 4.908c0-1.964.889-3.724 2.293-4.908l-.322-.382a6.903 6.903 0 0 0-2.471 5.29h.5Zm2.293 4.909a6.403 6.403 0 0 1-2.293-4.909h-.5a6.9 6.9 0 0 0 2.471 5.29l.322-.381Z"
                            fill="#000"></path>
                    </svg>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/logo_blanc_add_last_transparent.png';
import { ShoppingCart, User, Menu, X } from 'lucide-react';
import CartSidebar from '../components/CartSideBar';

const NAV_LINKS = [
    { text: 'HOME', href: '/' },
    { text: 'ABOUT', href: '/about' },
    { text: 'PRODUCTS', href: '/allproductspage' },
];

const Header = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [isCartOpen, setCartOpen] = useState(false); // État pour la sidebar du panier
    const navigate = useNavigate(); // Utilise useNavigate

    useEffect(() => {
        const handleScroll = () => {
            setIsSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => setMenuOpen(!isMenuOpen);
    const toggleCart = () => setCartOpen(!isCartOpen);

    const handleUserClick = () => {
        const token = localStorage.getItem('token');
        if (token) {
            // Rediriger vers la page client si le token est valide
            navigate('/client');
        } else {
            // Sinon, rediriger vers la page de connexion
            navigate('/auth');
        }
    };

    return (
        <>
            <header
                className={`fixed top-0 left-0 right-0 w-full transition-all duration-300 ease-in-out z-50 font-binaria ${
                    isSticky
                        ? 'bg-black bg-opacity-70 backdrop-blur-md h-16 md:h-20'
                        : 'bg-black h-[100px] md:h-[120px] lg:h-[149px]'
                }`}
            >
                <div className="container mx-auto px-4 h-full flex justify-between items-center">
                    <div className="flex items-center">
                        <Link to="/" className="flex items-center">
                            <img
                                src={logo}
                                alt="Logo"
                                className={`transition-all duration-300 ease-in-out ${isSticky ? 'h-8' : 'h-12 md:h-16'}`}
                            />
                        </Link>
                        <button onClick={toggleMenu} className="ml-4 md:hidden text-white focus:outline-none">
                            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>

                    <nav className="hidden md:flex items-center space-x-8">
                        {NAV_LINKS.map((link, index) => (
                            <Link
                                key={index}
                                to={link.href}
                                className="text-white hover:text-gray-300 transition-colors duration-200 text-sm lg:text-base"
                            >
                                {link.text}
                            </Link>
                        ))}
                    </nav>

                    <div className="flex items-center space-x-4 md:space-x-8">
                        <button onClick={toggleCart} className="text-white hover:text-gray-300 focus:outline-none z-50">
                            <ShoppingCart size={24} />
                        </button>
                        <button onClick={handleUserClick} className="text-white hover:text-gray-300 focus:outline-none">
                            <User size={24} />
                        </button>
                    </div>
                </div>

                {isMenuOpen && (
                    <nav className="md:hidden bg-black text-white py-4 px-4 space-y-4">
                        {NAV_LINKS.map((link, index) => (
                            <Link
                                key={index}
                                to={link.href}
                                className="block text-white hover:text-gray-300 transition-colors duration-200 text-lg"
                            >
                                {link.text}
                            </Link>
                        ))}
                    </nav>
                )}
            </header>

            <CartSidebar isOpen={isCartOpen} toggleCart={toggleCart} />

            <div className="pt-[149px]">
                {/* Ton contenu ici */}
            </div>
        </>
    );
};

export default Header;

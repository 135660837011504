type IconlyIconProps = {
    size?:number;
    color?:string;
}

export const IconlyHeart = ({ size = 24, color = "#000000" }: IconlyIconProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.9068 10.698H17.8818C17.4678 10.683 17.1428 10.337 17.1568 9.92298C17.1888 8.97198 16.7238 8.35498 15.9128 8.27198C15.5008 8.22998 15.2008 7.86198 15.2428 7.44998C15.2858 7.03898 15.6558 6.73598 16.0658 6.77998C17.6738 6.94398 18.7148 8.22698 18.6558 9.97298C18.6418 10.379 18.3088 10.698 17.9068 10.698ZM17.8248 3.28398C16.1048 2.73398 13.7238 2.96198 12.2378 4.64798C10.6768 2.97398 8.37679 2.73098 6.66879 3.28498C2.75379 4.54498 1.53279 9.08098 2.64679 12.56V12.561C4.40479 18.032 10.2498 20.983 12.2518 20.983C14.0388 20.983 20.1158 18.087 21.8538 12.56C22.9678 9.08198 21.7438 4.54598 17.8248 3.28398Z" fill={color}></path>
        </svg>
    )
}
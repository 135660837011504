import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const DeliveryPolicyPage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                <div className="w-3/4 mx-auto bg-gray-100 p-8 rounded-lg shadow-lg font-bold font-binaria">
                    <h1 className="text-4xl font-bold mb-8">Politique de Livraison</h1>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Généralités</h2>
                        <p>
                            Chez <strong>Add-Last</strong>, nous nous engageons à vous offrir un service de livraison rapide et sécurisé pour
                            garantir votre satisfaction. Les présentes conditions de livraison décrivent les modalités d'expédition, les frais
                            associés, ainsi que les délais auxquels vous pouvez vous attendre.
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Zones de Livraison</h3>
                        <p>
                            Nous livrons principalement en France métropolitaine, en Corse, ainsi que dans certains pays de l'Union européenne.
                            Pour toute demande de livraison en dehors de ces zones, nous vous invitons à nous contacter pour des informations
                            personnalisées.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Délais de Livraison</h2>
                        <p>
                            Les délais de livraison varient en fonction de la zone géographique et du mode de livraison choisi. Voici un aperçu des
                            délais moyens :
                        </p>
                        <ul className="list-disc list-inside">
                            <li><strong>France métropolitaine :</strong> 3 à 5 jours ouvrés</li>
                            <li><strong>Corse et DOM-TOM :</strong> 7 à 10 jours ouvrés</li>
                            <li><strong>Union européenne :</strong> 5 à 7 jours ouvrés</li>
                        </ul>
                        <br/>
                        <p>
                            Les délais de livraison sont indiqués à titre indicatif et peuvent varier en fonction des périodes d'affluence (comme les fêtes)
                            ou des situations exceptionnelles (ex : grèves, pandémies, etc.). Nous nous efforçons toujours de vous tenir informé en cas de
                            retard.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Modes de Livraison</h2>
                        <p>
                            Nous proposons différents modes de livraison afin de répondre à vos besoins. Lors de la commande, vous pouvez choisir parmi
                            les options suivantes :
                        </p>
                        <ul className="list-disc list-inside">
                            <li><strong>Livraison standard :</strong> Livraison effectuée par nos partenaires transporteurs, avec un suivi en ligne disponible.</li>
                            <li><strong>Livraison express :</strong> Service de livraison plus rapide, disponible pour les commandes en France métropolitaine. Des frais supplémentaires peuvent s'appliquer.</li>
                            <li><strong>Retrait en point relais :</strong> Vous pouvez choisir de récupérer votre colis dans un point relais proche de chez vous.</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Frais de Livraison</h2>
                        <p>
                            Les frais de livraison sont calculés en fonction du poids, des dimensions du colis, et de la destination. Voici un aperçu des
                            frais de livraison pour les commandes standard :
                        </p>
                        <ul className="list-disc list-inside">
                            <li><strong>France métropolitaine :</strong> Livraison gratuite pour les commandes de plus de 50€. Pour les commandes inférieures, des frais de 4,99€ s'appliquent.</li>
                            <li><strong>Corse et DOM-TOM :</strong> Frais à partir de 9,99€, calculés en fonction de la zone et du poids du colis.</li>
                            <li><strong>Union européenne :</strong> Frais de 12,99€ pour les commandes de moins de 100€. Livraison gratuite au-delà.</li>
                        </ul>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Suivi de la Livraison</h2>
                        <p>
                            Dès l'expédition de votre commande, un numéro de suivi vous sera envoyé par e-mail. Vous pourrez suivre l'acheminement de votre colis
                            directement sur le site du transporteur choisi.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Problèmes de Livraison</h2>
                        <p>
                            Si vous rencontrez un problème avec la réception de votre commande, veuillez nous contacter dans les plus brefs délais afin que nous
                            puissions enquêter et trouver une solution adaptée. En cas de colis perdu ou endommagé, nous vous proposerons une réexpédition ou un
                            remboursement, selon votre préférence.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Modification ou Annulation de la Commande</h2>
                        <p>
                            Si vous souhaitez modifier ou annuler votre commande après l'avoir passée, vous devez nous contacter rapidement. Tant que la commande
                            n'a pas encore été expédiée, nous pourrons effectuer les modifications ou procéder à l'annulation. Une fois la commande expédiée, toute
                            modification ou annulation devra passer par notre procédure de retour.
                        </p>
                    </section>

                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Contact</h2>
                        <p>
                            Si vous avez des questions supplémentaires concernant notre politique de livraison, vous pouvez nous contacter à l'adresse suivante :
                            <a href="mailto:contact@add-last.com" className="text-red-500 hover:underline"> contact@add-last.com</a>.
                        </p>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default DeliveryPolicyPage;
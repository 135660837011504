import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const TrackingPage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                <h1 className="text-3xl font-bold mb-4">Suivi de Colis</h1>
                <p>Here you can put your tracking content.</p>
            </main>
            <Footer />
        </div>
    );
};

export default TrackingPage;
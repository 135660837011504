import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react'; // Utilise un modal pour une alerte plus stylisée

interface Product {
    id: number;
    name: string;
    brand: string;
    price: number;
    description: string;
    image_1: string;
    is_hero: boolean;
}

interface HeroProductSelectorProps {
    products: Product[];
    onHeroSelection: (productId: number, updatedData?: Partial<Product>) => void;
}

const HeroProductSelector: React.FC<HeroProductSelectorProps> = ({ products, onHeroSelection }) => {
    const [selectedHeroId, setSelectedHeroId] = useState<number | null>(null);
    const [sortedProducts, setSortedProducts] = useState<Product[]>([]);
    const [sortConfig, setSortConfig] = useState<{ key: keyof Product; direction: 'asc' | 'desc' } | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [productToConfirm, setProductToConfirm] = useState<Product | null>(null);

    useEffect(() => {
        const currentHero = products.find(product => product.is_hero);
        if (currentHero) {
            setSelectedHeroId(currentHero.id);
        }
        setSortedProducts(products);
    }, [products]);

    const handleHeroSelection = (productId: number) => {
        const product = products.find(p => p.id === productId);
        if (!product) return;

        setProductToConfirm(product);
        setIsModalOpen(true); // Ouvre la popup pour confirmer
    };

    const confirmHeroSelection = () => {
        if (productToConfirm) {
            setSelectedHeroId(productToConfirm.id);
            onHeroSelection(productToConfirm.id);
        }
        setIsModalOpen(false); // Ferme la popup après la confirmation
    };

    const handleSort = (key: keyof Product) => {
        let direction: 'asc' | 'desc' = 'asc';

        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }

        const sortedArray = [...products].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });

        setSortedProducts(sortedArray);
        setSortConfig({ key, direction });
    };

    const renderSortIcon = (key: keyof Product) => {
        if (!sortConfig || sortConfig.key !== key) {
            return null;
        }
        return sortConfig.direction === 'asc' ? '↑' : '↓';
    };

    return (
        <div className="max-w-full mx-auto mt-8">
            <table className="min-w-full bg-white shadow-lg rounded-lg overflow-hidden">
                <thead>
                <tr className="bg-gray-100">
                    <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('id')}>
                        ID {renderSortIcon('id')}
                    </th>
                    <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('name')}>
                        Nom {renderSortIcon('name')}
                    </th>
                    <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('brand')}>
                        Marque {renderSortIcon('brand')}
                    </th>
                    <th className="px-4 py-2 cursor-pointer" onClick={() => handleSort('price')}>
                        Prix {renderSortIcon('price')}
                    </th>
                    <th className="px-4 py-2">Image</th>
                    <th className="px-4 py-2">Action</th>
                </tr>
                </thead>
                <tbody>
                {sortedProducts.map(product => (
                    <tr key={product.id} className="border-t">
                        <td className="border px-4 py-2 text-center">{product.id}</td>
                        <td className="border px-4 py-2 text-center">{product.name}</td>
                        <td className="border px-4 py-2 text-center">{product.brand}</td>
                        <td className="border px-4 py-2 text-center">{product.price} €</td>
                        <td className="border px-4 py-2 text-center">
                            <img
                                src={product.image_1}
                                alt={`Image of ${product.name}`}
                                className="w-16 h-16 object-contain mx-auto"
                            />
                        </td>
                        <td className="border px-4 py-2 text-center">
                            <button
                                className={`py-2 px-4 rounded ${selectedHeroId === product.id ? 'bg-green-500 text-white' : 'bg-gray-300'}`}
                                onClick={() => handleHeroSelection(product.id)}
                            >
                                {selectedHeroId === product.id ? 'Produit en avant' : 'Mettre en avant'}
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {/* Modal pour confirmation */}
            {productToConfirm && (
                <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} className="relative z-50">
                    <div className="fixed inset-0 bg-black bg-opacity-30" />
                    <div className="fixed inset-0 flex items-center justify-center">
                        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto">
                            <Dialog.Title className="text-xl font-semibold mb-4">
                                Confirmer la mise en avant
                            </Dialog.Title>
                            <Dialog.Description>
                                Voulez-vous vraiment mettre en avant le produit <strong>{productToConfirm.name}</strong> ?
                            </Dialog.Description>
                            <div className="mt-6 flex justify-end space-x-4">
                                <button
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-4 rounded"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Annuler
                                </button>
                                <button
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={confirmHeroSelection}
                                >
                                    Confirmer
                                </button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default HeroProductSelector;
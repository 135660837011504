import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const TermsOfServicePage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                <div className="w-3/4 mx-auto bg-gray-100 p-8 rounded-lg shadow-lg font-bold font-binaria">
                    <h1 className="text-4xl font-bold mb-8">Conditions Générales de Vente</h1>

                    {/* Introduction */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Introduction</h2>
                        <p>
                            Les présentes conditions générales de vente (CGV) régissent les relations contractuelles entre <strong>Add-Last</strong>
                            et toute personne physique ou morale souhaitant effectuer un achat via notre site internet <a href="https://add-last.com" className="text-red-500 hover:underline">add-last.com</a>.
                        </p>
                    </section>

                    {/* Acceptation des Conditions */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Acceptation des Conditions</h2>
                        <p>
                            Toute commande passée sur notre site implique l'acceptation sans réserve des présentes conditions générales de vente.
                            Nous nous réservons le droit de modifier ces conditions à tout moment, sans préavis. Les conditions applicables sont celles
                            en vigueur à la date de la commande.
                        </p>
                    </section>

                    {/* Produits et Disponibilité */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Produits et Disponibilité</h2>
                        <p>
                            Nous mettons tout en œuvre pour décrire et présenter les produits vendus sur notre site avec la plus grande exactitude. Toutefois,
                            en cas d'erreur ou omission dans la présentation des produits, notre responsabilité ne saurait être engagée. La disponibilité des
                            produits est mise à jour régulièrement, mais en cas de rupture de stock, nous nous engageons à informer l'acheteur dans les plus
                            brefs délais et à lui proposer une solution alternative.
                        </p>
                    </section>

                    {/* Commande */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Commande</h2>
                        <p>
                            Toute commande effectuée sur <strong>Add-Last</strong> vaut acceptation des prix et des descriptions des produits disponibles à la
                            vente. Après validation de la commande, vous recevrez un e-mail de confirmation. La commande ne sera validée qu'après réception
                            du paiement intégral.
                        </p>
                    </section>

                    {/* Prix et Paiement */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Prix et Modalités de Paiement</h2>
                        <p>
                            Les prix affichés sur notre site sont en euros, toutes taxes comprises (TTC). Nous nous réservons le droit de modifier les prix
                            à tout moment, mais les produits seront facturés sur la base des tarifs en vigueur au moment de la validation de la commande.
                        </p>
                        <p>
                            Le paiement peut être effectué par carte bancaire, PayPal, ou tout autre moyen de paiement indiqué lors du processus de commande.
                            La commande ne sera expédiée qu'après réception du paiement.
                        </p>
                    </section>

                    {/* Livraison */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Livraison</h2>
                        <p>
                            Les délais de livraison indiqués sur notre site sont donnés à titre indicatif et peuvent varier en fonction de la disponibilité
                            des produits et des transporteurs. Nous nous efforçons de respecter les délais annoncés, mais un éventuel retard de livraison ne
                            saurait engager notre responsabilité.
                        </p>
                        <p>
                            Les frais de livraison sont précisés lors du processus de commande. En cas de non-réception du colis, veuillez nous contacter afin
                            que nous puissions enquêter avec le transporteur.
                        </p>
                    </section>

                    {/* Droit de Rétractation */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Droit de Rétractation</h2>
                        <p>
                            Conformément à la loi, vous disposez d'un délai de 14 jours à compter de la réception de votre commande pour exercer votre droit de
                            rétractation sans avoir à justifier de motifs. Pour exercer ce droit, vous devez nous notifier votre décision par e-mail à
                            <a href="mailto:contact@add-last.com" className="text-red-500 hover:underline">contact@add-last.com</a>.
                        </p>
                        <p>
                            Les produits retournés doivent être dans leur état d'origine, non utilisés, et dans leur emballage d'origine. Les frais de retour
                            sont à la charge du client. Après réception et vérification des produits, nous procéderons au remboursement dans un délai de 14 jours.
                        </p>
                    </section>

                    {/* Garantie et Service Après-Vente */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Garantie et Service Après-Vente</h2>
                        <p>
                            Tous nos produits bénéficient de la garantie légale de conformité. En cas de défaut ou de dysfonctionnement, vous pouvez nous
                            contacter pour organiser le retour du produit et bénéficier d'une réparation, d'un échange ou d'un remboursement, selon les
                            cas. Les frais de retour pour les produits défectueux sont pris en charge par <strong>Add-Last</strong>.
                        </p>
                    </section>

                    {/* Responsabilité */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Responsabilité</h2>
                        <p>
                            La responsabilité de <strong>Add-Last</strong> ne pourra être engagée en cas de dommages résultant de l'utilisation inappropriée
                            du produit acheté, de la non-conformité des informations fournies par l'utilisateur lors de la commande, ou en cas de force majeure.
                        </p>
                    </section>

                    {/* Protection des Données Personnelles */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Protection des Données Personnelles</h2>
                        <p>
                            Nous nous engageons à protéger vos données personnelles. Les informations collectées lors du processus de commande sont
                            nécessaires pour le traitement et la livraison des commandes. Conformément à la loi informatique et libertés, vous disposez d'un
                            droit d'accès, de rectification et de suppression des données vous concernant. Pour exercer ce droit, veuillez nous contacter à
                            <a href="mailto:contact@add-last.com" className="text-red-500 hover:underline">contact@add-last.com</a>.
                        </p>
                    </section>

                    {/* Litiges */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Litiges et Médiation</h2>
                        <p>
                            En cas de litige relatif à l'exécution des présentes conditions générales de vente, nous vous invitons à nous contacter dans un
                            premier temps afin de trouver une solution amiable. À défaut d'accord, le litige pourra être soumis aux tribunaux compétents.
                        </p>
                    </section>

                    {/* Modifications des Conditions Générales de Vente */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Modifications des Conditions Générales de Vente</h2>
                        <p>
                            Nous nous réservons le droit de modifier ces conditions générales de vente à tout moment. Les nouvelles conditions seront
                            applicables à toutes les commandes passées après la date de modification.
                        </p>
                    </section>

                    {/* Contact */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Contact</h2>
                        <p>
                            Pour toute question concernant nos conditions générales de vente, vous pouvez nous contacter à l'adresse suivante :
                            <a href="mailto:contact@add-last.com" className="text-red-500 hover:underline"> contact@add-last.com</a>.
                        </p>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default TermsOfServicePage;
import { useState, useEffect } from 'react';
import { Heart } from 'lucide-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ModalVote from './ModalVote';
import NotificationPortal from './NotificationPortal'; // Import du composant de notification

interface ProductCardProps {
    id: number;
    title?: string;
    likes?: number;
    likesObjective?: number;
    price?: number | string;
    imageSrc?: string;
    showAdditionalInfo?: boolean;
}

export default function ProductCardV2({
                                          id,
                                          title = 'Titre',
                                          likes = 0,
                                          likesObjective = 0,
                                          price = 0,
                                          imageSrc = 'default.svg',
                                          showAdditionalInfo = false,
                                      }: ProductCardProps) {
    const likePercentage = likesObjective > 0 ? Math.min((likes / likesObjective) * 100, 100) : 0;
    const priceValue = typeof price === 'number' ? price : parseFloat(price.toString());

    const [showModal, setShowModal] = useState(false);
    const [rememberChoice, setRememberChoice] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userToken, setUserToken] = useState<string | null>(null);
    const [currentLikes, setCurrentLikes] = useState(likes);
    const [isHeartHovered, setIsHeartHovered] = useState(false);
    const [activeVoteProduct, setActiveVoteProduct] = useState<string | null>(null);
    const [showNotification, setShowNotification] = useState({ isVisible: false, type: 'error', message: '' });
    const [voteSuccess, setVoteSuccess] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            setIsAuthenticated(false);
            setUserToken(null);
            return;
        }

        setIsAuthenticated(true);
        setUserToken(token);
        getActiveVote(token);
    }, [id]);

    // Récupérer le vote actif
    async function getActiveVote(token: string) {
        try {
            const response = await axios.get('http://localhost:3001/api/votes/active', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const activeVote = response.data;
            if (activeVote && activeVote.product_id !== id) {
                setActiveVoteProduct(activeVote.Product.name); // Stocke le nom du produit déjà voté
            } else {
                setActiveVoteProduct(null); // Pas de vote actif ou vote pour le produit en cours
            }
        } catch (error: any) {
            if (error.response && error.response.status === 404) {
                setActiveVoteProduct(null); // Pas de vote actif
            } else {
                console.error('Erreur lors de la récupération du vote actif :', error);
            }
        }
    }

    // Gestion du clic sur l'icône de vote
    function handleHeartClick(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();

        if (!isAuthenticated) {
            setShowNotification({
                isVisible: true,
                type: 'error',
                message: 'Vous devez être connecté pour voter.',
            });
            setTimeout(() => {
                setShowNotification((prev) => ({ ...prev, isVisible: false })); // Masquer la notification après 3 secondes
            }, 3000);
        } else {
            setShowModal(true);
        }
    }

    // Enregistrement du vote
    async function handleVote() {
        try {
            const response = await axios.post(
                'http://localhost:3001/api/votes',
                {
                    product_id: id,
                    duration: 1, // Exemple de durée en mois
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            setShowModal(false);
            setVoteSuccess(true); // Affiche la notification de succès

            // Mise à jour du compteur local
            setCurrentLikes((prevLikes) => prevLikes + 1);

            setShowNotification({
                isVisible: true,
                type: 'success',
                message: 'Votre vote a bien été enregistré.',
            });

            setTimeout(() => {
                setVoteSuccess(false);
                setShowNotification((prev) => ({ ...prev, isVisible: false })); // Cache la notification après 3 secondes
                window.location.reload(); // Recharge la page après le vote
            }, 3000);
        } catch (error) {
            console.error('Erreur lors de l\'enregistrement du vote:', error);
            alert('Une erreur est survenue lors de l\'enregistrement de votre vote.');
            setShowModal(false);
        }
    }

    return (
        <div>
            <div className="bg-white rounded-lg shadow-lg overflow-hidden max-w-sm mx-auto font-binaria transform transition-transform hover:scale-105 hover:shadow-xl">
                <div className="relative aspect-square">
                    <Link to={`/productpage/${id}`}>
                        <img
                            src={imageSrc}
                            alt={title}
                            className="w-full h-full object-cover"
                        />
                    </Link>
                    <button
                        className="absolute top-2 right-2 bg-white rounded-full p-2 transition-transform duration-200"
                        onClick={handleHeartClick}
                        onMouseEnter={() => setIsHeartHovered(true)}
                        onMouseLeave={() => setIsHeartHovered(false)}
                    >
                        <Heart
                            className={`w-9 h-9 transition-transform duration-200 ${
                                isHeartHovered ? 'scale-75 text-red-600' : 'text-red-500'
                            }`}
                        />
                    </button>
                </div>
                <div className="p-4">
                    <h2 className="text-xl font-semibold mb-4 text-center">{title}</h2>
                    <div className="flex items-center justify-between mb-2">
                        <div className="flex items-center">
                            <Heart className="w-5 h-5 text-red-500 mr-1" />
                            <span className="text-sm text-gray-600">{currentLikes} j'aime</span>
                        </div>
                        <span className="text-sm text-gray-600">Objectif : {likesObjective}</span>
                    </div>
                    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                        <div
                            className="h-2.5 rounded-full"
                            style={{
                                width: `${likePercentage}%`,
                                backgroundColor: 'rgb(226,85,53)',
                            }}
                        ></div>
                    </div>
                    <div className="flex items-center justify-between mb-4">
                        <span className="text-2xl font-bold">{!isNaN(priceValue) ? priceValue.toFixed(2) : '0.00'} €</span>
                        <Link to={`/productpage/${id}`}>
                            <button className="bg-black text-white font-medium py-2 px-4 rounded-md hover:bg-[rgb(226,85,53)] transition-colors duration-200">
                                Voir le produit
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Modal */}
            <ModalVote isOpen={showModal} onClose={() => setShowModal(false)}>
                {activeVoteProduct ? (
                    <div>
                        <p className="text-lg font-semibold mb-4">
                            Vous avez déjà voté pour <strong>{activeVoteProduct}</strong>.
                            Ce vote sera annulé si vous votez pour ce produit.
                        </p>
                        <div className="mt-4 flex space-x-2">
                            <button onClick={handleVote} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
                                Oui, voter
                            </button>
                            <button onClick={() => setShowModal(false)} className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded">
                                Annuler
                            </button>
                        </div>
                    </div>
                ) : (
                    <div>
                        <p className="text-lg font-semibold mb-4">Êtes-vous sûr de vouloir voter pour ce produit ?</p>
                        <div className="mt-4 flex items-center">
                            <input
                                type="checkbox"
                                id="rememberChoice"
                                className="mr-2"
                                checked={rememberChoice}
                                onChange={(e) => setRememberChoice(e.target.checked)}
                            />
                            <label htmlFor="rememberChoice">Mémoriser mon choix</label>
                        </div>
                        <div className="mt-4 flex space-x-2">
                            <button onClick={handleVote} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
                                Oui, voter
                            </button>
                            <button onClick={() => setShowModal(false)} className="bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded">
                                Annuler
                            </button>
                        </div>
                    </div>
                )}
            </ModalVote>

            {/* Notifications */}
            <NotificationPortal
                type={showNotification.type as 'success' | 'error'}
                message={showNotification.message}
                isVisible={showNotification.isVisible}
            />
        </div>
    );
}

import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import FaqPage from './pages/FooterPages/FaqPage';
import PartnershipPage from './pages/FooterPages/PartnershipPage';
import TrackingPage from './pages/FooterPages/TrackingPage';
import ContactPage from './pages/FooterPages/ContactPage';
import LegalPage from './pages/FooterPages/LegalPage';
import DeliveryPolicyPage from './pages/FooterPages/DeliveryPolicyPage';
import PrivacyPolicyPage from './pages/FooterPages/PrivacyPolicyPage';
import RefundPolicyPage from './pages/FooterPages/RefundPolicyPage';
import TermsOfServicePage from './pages/FooterPages/TermsOfServicePage';
import TermsOfUsePage from './pages/FooterPages/TermsOfUsePage';
import AuthPage from './pages/AuthPage';
import ClientPage from './pages/ClientPages/ClientPage';
import InformationsPage from './pages/ClientPages/InformationsPage';
import AddressesPage from './pages/ClientPages/AddressesPage';
import OrdersPage from './pages/ClientPages/OrdersPage';
import CreditsPage from './pages/ClientPages/CreditsPage';
import PersonalDataPage from './pages/ClientPages/PersonalDataPage';
import ListsPage from './pages/ClientPages/ListsPage';
import ProductPage from './pages/ProductPage';
import AdminPage from './pages/AdminPage';
import AllProductsPage from './pages/AllProductsPage';
import CartPage from './pages/CartPage';
import { CartProvider } from './context/CartContext'; // Import du CartProvider
import CartSidebar from '../src/components/CartSideBar'; // Import de CartSidebar
import AboutPage from "./pages/AboutPage";
import DebugPage from "./pages/DebugPage";
import CancelPage from "./pages/CancelPage";
import SuccessPage from "./pages/SuccessPage";

function App() {
    const [isCartOpen, setCartOpen] = useState(false); // Gestion de l'ouverture/fermeture du panier

    const toggleCart = () => {
        setCartOpen(!isCartOpen);
    };

    return (
        <CartProvider>
            <Router>
                <CartSidebar isOpen={isCartOpen} toggleCart={toggleCart} /> {/* Sidebar du panier */}
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/debug" element={<DebugPage />} />
                    <Route path="/faq" element={<FaqPage />} />
                    <Route path="/partnership" element={<PartnershipPage />} />
                    <Route path="/tracking" element={<TrackingPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/legal" element={<LegalPage />} />
                    <Route path="/delivery-policy" element={<DeliveryPolicyPage />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route path="/refund-policy" element={<RefundPolicyPage />} />
                    <Route path="/terms-of-service" element={<TermsOfServicePage />} />
                    <Route path="/terms-of-use" element={<TermsOfUsePage />} />
                    <Route path="/auth" element={<AuthPage />} />
                    <Route path="/client" element={<ClientPage />} />
                    <Route path="/informations" element={<InformationsPage />} />
                    <Route path="/addresses" element={<AddressesPage />} />
                    <Route path="/orders" element={<OrdersPage />} />
                    <Route path="/credits" element={<CreditsPage />} />
                    <Route path="/personal-data" element={<PersonalDataPage />} />
                    <Route path="/lists" element={<ListsPage />} />
                    <Route path="/productpage/:id" element={<ProductPage toggleCart={toggleCart} />} /> {/* Passer toggleCart */}
                    <Route path="/adminpage" element={<AdminPage />} />
                    <Route path="/allproductspage" element={<AllProductsPage />} />
                    <Route path="/cartpage" element={<CartPage />} />
                    <Route path="/cancel" element={<CancelPage />} />
                    <Route path="/success" element={<SuccessPage />} />
                </Routes>
            </Router>
        </CartProvider>
    );
}

export default App;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { jwtDecode } from 'jwt-decode';  // Import correct

axios.defaults.baseURL = 'http://localhost:3001';

const AuthPage = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [loginData, setLoginData] = useState({ email: '', password: '' });
    const [registerData, setRegisterData] = useState({
        title: 'M',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        date_of_birth: '',
        acceptTerms: false,
        subscribeNewsletter: false
    });
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLoginChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginData({ ...loginData, [e.target.name]: e.target.value });
    };

    const handleRegisterChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setRegisterData({ ...registerData, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRegisterData({ ...registerData, [e.target.name]: e.target.checked });
    };

    const handleLoginSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/auth/login', loginData);
            if (response.data.token) {
                // Stocker le token JWT dans le localStorage
                const token = response.data.token;
                localStorage.setItem('token', token);

                // Décoder le token JWT pour récupérer le user_id
                const decodedToken = jwtDecode<{ userId: string }>(token);  // Utilisation correcte
                const user_id = decodedToken.userId;

                // Stocker le user_id dans le localStorage
                localStorage.setItem('userId', user_id);

                // Rediriger vers la page client
                navigate('/client');
            }
        } catch (error) {
            setErrorMessage('Connexion échouée. Veuillez vérifier vos identifiants.');
        }
    };

    const handleRegisterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!registerData.acceptTerms) {
            setErrorMessage('Vous devez accepter les conditions générales pour vous inscrire.');
            return;
        }

        const { acceptTerms, ...dataToSend } = registerData;

        try {
            await axios.post('/api/auth/register', dataToSend);
            setIsLogin(true);  // Rediriger vers la page de connexion après l'inscription
        } catch (error) {
            setErrorMessage('Inscription échouée. Veuillez réessayer.');
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <Header/>
            <br/> <br/> <br/>
            <main className="flex-grow flex justify-center items-center">
                <section className="w-full max-w-md mx-auto p-6 bg-gray-100 rounded-lg shadow-lg text-center">
                    <h2 className="font-binaria text-3xl font-bold mb-6 text-center">
                        {isLogin ? 'CONNEXION' : 'INSCRIPTION'}
                    </h2>

                    <form onSubmit={isLogin ? handleLoginSubmit : handleRegisterSubmit}>
                        <div className="grid grid-cols-1 gap-6">
                            {isLogin ? (
                                <>
                                    <div className="flex flex-col">
                                        <label htmlFor="email" className="font-binaria text-lg font-semibold mb-2">
                                            E-mail
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={loginData.email}
                                            onChange={handleLoginChange}
                                            placeholder="Votre e-mail"
                                            className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                                            required
                                        />
                                    </div>

                                    <div className="flex flex-col">
                                        <label htmlFor="password" className="font-binaria text-lg font-semibold mb-2">
                                            Mot de passe
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={loginData.password}
                                            onChange={handleLoginChange}
                                            placeholder="Votre mot de passe"
                                            className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                                            required
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    {/* Titre (M, Mme) */}
                                    <div className="flex flex-col">
                                        <label htmlFor="title" className="font-binaria text-lg font-semibold mb-2">
                                            Titre
                                        </label>
                                        <select
                                            name="title"
                                            value={registerData.title}
                                            onChange={handleRegisterChange}
                                            className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                                        >
                                            <option value="M">M</option>
                                            <option value="Mme">Mme</option>
                                        </select>
                                    </div>

                                    {/* Nom et Prénom sur une même ligne */}
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="first_name"
                                                   className="font-binaria text-lg font-semibold mb-2">
                                                Prénom
                                            </label>
                                            <input
                                                type="text"
                                                name="first_name"
                                                value={registerData.first_name}
                                                onChange={handleRegisterChange}
                                                placeholder="Votre prénom"
                                                className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="last_name"
                                                   className="font-binaria text-lg font-semibold mb-2">
                                                Nom
                                            </label>
                                            <input
                                                type="text"
                                                name="last_name"
                                                value={registerData.last_name}
                                                onChange={handleRegisterChange}
                                                placeholder="Votre nom"
                                                className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* E-mail */}
                                    <div className="flex flex-col">
                                        <label htmlFor="email" className="font-binaria text-lg font-semibold mb-2">
                                            E-mail
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={registerData.email}
                                            onChange={handleRegisterChange}
                                            placeholder="Votre e-mail"
                                            className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                                            required
                                        />
                                    </div>

                                    {/* Mot de passe */}
                                    <div className="flex flex-col">
                                        <label htmlFor="password" className="font-binaria text-lg font-semibold mb-2">
                                            Mot de passe
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            value={registerData.password}
                                            onChange={handleRegisterChange}
                                            placeholder="Votre mot de passe"
                                            className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                                            required
                                        />
                                    </div>

                                    {/* Date de naissance */}
                                    <div className="flex flex-col">
                                        <label htmlFor="date_of_birth"
                                               className="font-binaria text-lg font-semibold mb-2">
                                            Date de naissance
                                        </label>
                                        <input
                                            type="date"
                                            name="date_of_birth"
                                            value={registerData.date_of_birth}
                                            onChange={handleRegisterChange}
                                            className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                                        />
                                    </div>

                                    {/* Conditions générales */}
                                    <div className="flex items-center justify-start mt-4">
                                        <input
                                            type="checkbox"
                                            name="acceptTerms"
                                            checked={registerData.acceptTerms}
                                            onChange={handleCheckboxChange}
                                            required
                                            className="mr-2"
                                        />
                                        <label htmlFor="acceptTerms" className="font-binaria text-md">
                                            J'accepte les conditions générales et la politique de confidentialité
                                        </label>
                                    </div>

                                    {/* Souscription à la newsletter */}
                                    <div className="flex items-center justify-start mt-2">
                                        <input
                                            type="checkbox"
                                            name="subscribeNewsletter"
                                            checked={registerData.subscribeNewsletter}
                                            onChange={handleCheckboxChange}
                                            className="mr-2"
                                        />
                                        <label htmlFor="subscribeNewsletter" className="font-binaria text-md">
                                            Recevoir notre newsletter
                                        </label>
                                    </div>
                                </>
                            )}

                            {/* Bouton de soumission */}
                            <div className="flex justify-center mt-4">
                                <button
                                    className="font-binaria bg-orange-500 text-white px-8 py-3 rounded-md font-semibold hover:bg-orange-600 transition-all">
                                    {isLogin ? 'Connexion' : 'Inscription'}
                                </button>
                            </div>
                        </div>
                    </form>

                    {/* Basculer entre Connexion et Inscription */}
                    <div className="mt-4 text-center font-binaria">
                        {isLogin ? (
                            <p>
                                Pas de compte ?{' '}
                                <span
                                    className="text-orange-500 cursor-pointer"
                                    onClick={() => setIsLogin(false)}
                                >
                                    Créez-en un
                                </span>
                            </p>
                        ) : (
                            <p>
                                Vous avez déjà un compte ?{' '}
                                <span
                                    className="text-orange-500 cursor-pointer"
                                    onClick={() => setIsLogin(true)}
                                >
                                    Connectez-vous
                                </span>
                            </p>
                        )}
                    </div>

                    {errorMessage && <div className="text-red-500 mt-4 text-center">{errorMessage}</div>}
                </section>
            </main>
            <br/> <br/> <br/>
            <Footer/>

        </div>
    );
};

export default AuthPage;
import React, { useState } from 'react';
import axios from 'axios';
import RevenueChart from './RevenueChart'; // Importer le nouveau composant

interface Product {
    id: number;
    image_1: string;
}

interface Order {
    id: number;
    user_id: number;
    created_at: string;
    total_price: number | string;
    status: string;
    order_details: string; // Va contenir une chaîne JSON
    stripe_payment_intent: string;
    address_id: number;
}

interface OrderListProps {
    orders: Order[];
}

const OrderList: React.FC<OrderListProps> = ({ orders }) => {
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [products, setProducts] = useState<{ [key: number]: Product }>({}); // Stocker les produits récupérés

    // Fonction pour récupérer les informations du produit via son product_id
    const fetchProductDetails = async (product_id: number) => {
        try {
            if (!products[product_id]) {
                const response = await axios.get(`/api/products/${product_id}`);
                setProducts((prevProducts) => ({
                    ...prevProducts,
                    [product_id]: response.data,
                }));
            }
        } catch (error) {
            console.error('Erreur lors de la récupération du produit:', error);
        }
    };

    // Récupérer l'image du produit via son product_id
    const getProductImage = (product_id: number) => {
        const product = products[product_id];
        return product ? product.image_1 : '/placeholder.svg';
    };

    const openDetails = (order: Order) => {
        try {
            // S'assurer que order_details est bien parsé en tableau
            const parsedDetails = JSON.parse(order.order_details);

            setSelectedOrder({
                ...order,
                order_details: parsedDetails, // Remplace order_details par le tableau parsé
            });
            setIsDetailsOpen(true);

            // Récupérer les détails des produits
            parsedDetails.forEach((item: any) => {
                fetchProductDetails(item.product_id);
            });
        } catch (error) {
            console.error('Erreur lors du parsing des détails de la commande:', error);
        }
    };

    const closeDetails = () => {
        setIsDetailsOpen(false);
        setSelectedOrder(null);
    };

    return (
        <div>
            <h2 className="text-3xl font-bold mb-6">Liste des commandes</h2>

            {/* Ajouter le graphique du chiffre d'affaires */}
            <div className="mb-6">
                <RevenueChart orders={orders} />
            </div>

            <table className="min-w-full bg-white border border-gray-300 table-fixed shadow-md rounded-lg">
                <thead>
                <tr className="bg-gray-100">
                    <th className="py-3 px-4 border-b w-24">ID</th>
                    <th className="py-3 px-4 border-b w-48">Utilisateur</th>
                    <th className="py-3 px-4 border-b w-48">Date</th>
                    <th className="py-3 px-4 border-b w-32">Total</th>
                    <th className="py-3 px-4 border-b w-32">Statut</th>
                    <th className="py-3 px-4 border-b w-32">Action</th>
                </tr>
                </thead>
                <tbody>
                {orders.map((order) => (
                    <tr key={order.id} className="h-24 hover:bg-gray-50">
                        <td className="py-2 px-4 border-b">{order.id}</td>
                        <td className="py-2 px-4 border-b">{order.user_id}</td>
                        <td className="py-2 px-4 border-b">{new Date(order.created_at).toLocaleDateString()}</td>
                        <td className="py-2 px-4 border-b">{Number(order.total_price).toFixed(2)} €</td>
                        <td className="py-2 px-4 border-b capitalize">{order.status}</td>
                        <td className="py-2 px-4 border-b">
                            <button
                                className="text-blue-500 hover:underline"
                                onClick={() => openDetails(order)}
                            >
                                Voir les détails
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {isDetailsOpen && selectedOrder && (
                <div className="mt-6 p-6 border border-gray-300 rounded-md bg-white shadow-lg">
                    <h2 className="text-2xl font-bold mb-4">Détails de la commande</h2>
                    <p>
                        <strong>ID Commande : </strong>{selectedOrder.id}
                    </p>
                    <p>
                        <strong>Total : </strong>{selectedOrder.total_price} €
                    </p>
                    <p>
                        <strong>Statut : </strong>{selectedOrder.status}
                    </p>
                    <p>
                        <strong>Date : </strong>{new Date(selectedOrder.created_at).toLocaleDateString()}
                    </p>

                    <div>
                        <h3 className="font-bold mt-4 mb-2">Détails des articles :</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {Array.isArray(selectedOrder.order_details) ? (
                                selectedOrder.order_details.map((item: any, index: number) => (
                                    <div key={index} className="p-4 border border-gray-200 rounded-lg shadow-sm bg-gray-50">
                                        <img
                                            src={getProductImage(item.product_id)}
                                            alt={item.name}
                                            className="w-32 h-32 object-cover rounded-lg mb-4"
                                        />
                                        <p>
                                            <strong>Produit : </strong>{item.name}
                                        </p>
                                        <p>
                                            <strong>Prix : </strong>{item.price} €
                                        </p>
                                        <p>
                                            <strong>Taille : </strong>{item.size}
                                        </p>
                                        <p>
                                            <strong>Quantité : </strong>{item.quantity}
                                        </p>
                                    </div>
                                ))
                            ) : (
                                <p>Aucun détail de commande disponible.</p>
                            )}
                        </div>
                    </div>

                    <button
                        onClick={closeDetails}
                        className="mt-6 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-all"
                    >
                        Fermer
                    </button>
                </div>
            )}
        </div>
    );
};

export default OrderList;
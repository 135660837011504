"use client";
import WordFadeIn from "./word-fade-in";
import imagestep from "../../assets/ba8f4256db51be25d6ec34ca64eee257.jpg";

import React, { useState, useEffect, useRef } from "react";
import { Circle } from "lucide-react";

// Composant pour gérer l'animation d'une seule étape
function StepProgress({ activeStep, stepIdx, progress }: { activeStep: number; stepIdx: number; progress: number }) {
    const isActive = activeStep === stepIdx;
    const isCompleted = activeStep > stepIdx;

    return (
        <div className="h-full w-2 bg-gray-200 rounded-full relative overflow-hidden">
            <div
                className="bg-black w-full absolute top-0 rounded-full transition-all duration-500 ease-linear"
                style={{
                    height: `${isCompleted ? 100 : isActive ? progress * 100 : 0}%`, // Remplit en noir du haut vers le bas
                }}
            ></div>
        </div>
    );
}

// Fonction pour formater les titres et mettre certains mots en rouge
const formatTitle = (title: string) => {
    const formattedTitle = title
        .replace(/(Vote)/, '<span class="text-red-500">$1</span>')
        .replace(/(Précommande)/, '<span class="text-red-500">$1</span>')
        .replace(/(domicile)/, '<span class="text-red-500">$1</span>');

    return <span dangerouslySetInnerHTML={{ __html: formattedTitle }} />;
};

// Composant pour une étape individuelle
function Step({ step, stepIdx, activeStep, setActiveStep, setIsHovered }: any) {
    const isActive = activeStep === stepIdx;
    const isCompleted = activeStep > stepIdx;

    return (
        <div
            className={`relative flex items-start mb-16 last:mb-0 transition-all duration-300 ${
                isActive ? "scale-105 opacity-100" : "scale-95 opacity-50"
            }`}
            onMouseEnter={() => {
                setActiveStep(stepIdx);
                setIsHovered(true);
            }}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="mr-4">
                <div
                    className={`h-8 w-8 rounded-full border-4 ${
                        isCompleted ? "border-primary bg-primary" : isActive ? "border-primary bg-white" : "border-gray-300 bg-white"
                    } flex items-center justify-center transition-colors duration-300`}
                >
                    <Circle className={`h-4 w-4 ${isCompleted ? "text-white" : isActive ? "text-primary" : "text-gray-300"}`} />
                </div>
            </div>
            <div className={`flex-1 transition-opacity duration-300 ${isActive ? "opacity-100" : "opacity-50"}`}>
                <dt className="text-lg font-semibold text-gray-900 mb-2">{formatTitle(step.title)}</dt>
                <dd className="text-base text-gray-600">{step.description}</dd>
            </div>
        </div>
    );
}

// Composant principal
export default function HeroSteps() {
    const [activeStep, setActiveStep] = useState(0);
    const [progress, setProgress] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const animationRef = useRef<number | null>(null);

    const steps = [
        {
            title: "Vote pour ta sneaker préférée",
            description: "Explore notre collection et vote pour les modèles qui te plaisent le plus. Chaque vote compte pour lancer la production !",
        },
        {
            title: "Précommande ta sneaker exclusive",
            description: "Une fois le nombre de votes atteint, votre sneaker entre en production. Précommandez-la avant qu'elle ne disparaisse !",
        },
        {
            title: "Reçoit ta paire à domicile",
            description: "Dès la production terminée, recevez votre paire directement chez vous, fraîchement sortie de l'usine.",
        },
    ];

    // Fonction pour démarrer l'animation
    const startAnimation = () => {
        if (animationRef.current) cancelAnimationFrame(animationRef.current);
        let start: number | null = null;
        const animate = (timestamp: number) => {
            if (start === null) start = timestamp;
            const elapsed = timestamp - start;
            const newProgress = Math.min(elapsed / 5000, 1);
            setProgress(newProgress);

            if (newProgress < 1) {
                animationRef.current = requestAnimationFrame(animate);
            } else {
                setActiveStep((prevStep) => (prevStep + 1) % steps.length);
                setProgress(0);
                startAnimation(); // Redémarrage de l'animation
            }
        };
        animationRef.current = requestAnimationFrame(animate);
    };

    useEffect(() => {
        if (!isHovered) {
            // Relancer l'animation si le survol est terminé
            startAnimation();
        } else {
            // Arrêter l'animation pendant le survol
            if (animationRef.current) cancelAnimationFrame(animationRef.current);
        }
        // Nettoyage de l'animation au démontage
        return () => {
            if (animationRef.current) cancelAnimationFrame(animationRef.current);
        };
    }, [isHovered]); // Dépend de l'état du survol

    return (
        <div className="bg-white py-12 sm:py-16 lg:py-24 font-binaria">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl text-center mb-12 lg:mb-16">
                    <WordFadeIn words="Rejoignez la Révolution Sneaker" />
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Rejoignez la culture Sneaker de demain. Achetez des modèles exclusifs, connectez-vous avec des passionnés, et suivez les dernières tendances.
                        Commencez dès maintenant :
                    </p>
                </div>

                <div className="mx-auto mt-12 lg:mt-16 max-w-2xl lg:max-w-none">
                    <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-12">
                        {/* Centrer les steps et l'image en hauteur */}
                        <div className="w-full lg:w-1/2 order-2 lg:order-1 flex space-x-8">
                            {/* Espacement ajusté à gauche */}
                            {/* Barres de progression alignées avec les blocs de texte */}
                            <div className="flex flex-col items-center space-y-8">
                                {steps.map((_, stepIdx) => (
                                    <StepProgress key={stepIdx} activeStep={activeStep} stepIdx={stepIdx} progress={progress} />
                                ))}
                            </div>
                            <dl className="flex-1">
                                {steps.map((step, stepIdx) => (
                                    <Step
                                        key={stepIdx}
                                        step={step}
                                        stepIdx={stepIdx}
                                        activeStep={activeStep}
                                        setActiveStep={setActiveStep}
                                        setIsHovered={setIsHovered}
                                    />
                                ))}
                            </dl>
                        </div>
                        <div className="w-full lg:w-1/2 order-1 lg:order-2">
                            <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-2xl shadow-lg">
                                <img
                                    src={imagestep} // Image Sneaker
                                    alt="Sneaker sketch"
                                    className="w-full h-auto object-contain max-h-96 lg:max-h-full height=600&width=800"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-16 lg:mt-20 text-center">
                    <a
                        href="allproductspage"
                        className="rounded-md bg-black px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                    >
                        Join the Sneaker Revolution
                    </a>
                </div>
            </div>
        </div>
    );
}
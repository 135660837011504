import React, { useEffect, useState } from "react";
import { Trash2, Minus, Plus, ShoppingBag, Edit3 } from "lucide-react";
import { useCart } from "../context/CartContext";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";

// Charger Stripe avec la clé publique
const stripePromise = loadStripe('pk_test_51Q1QcyCYDJm0RDoT0M0eMr7Zp6KyOjUlmguJMBHXitQaTXHQKocO8Aitab5bCbZjvI0tlUbRzcqAx8KX2PTZxSiP00raw8CzPa');

export default function CartPage() {
    const { cartItems, removeFromCart, updateQuantity } = useCart();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userAddress, setUserAddress] = useState<any>(null);
    const [formData, setFormData] = useState({
        alias: '',
        first_name: '',
        last_name: '',
        address: '',
        address_2: '',
        postal_code: '',
        city: '',
        country: '',
        phone: ''
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [isAddressSectionOpen, setIsAddressSectionOpen] = useState(false);
    const [notification, setNotification] = useState<string | null>(null);
    const navigate = useNavigate();

    const subtotal = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
    const shipping = cartItems.length === 0 ? 0 : 5.99;
    const total = subtotal + shipping;

    useEffect(() => {
        const token = localStorage.getItem("token");

        if (!token) {
            setIsAuthenticated(false);
            return;
        }

        setIsAuthenticated(true);
        fetchUserAddress();
    }, []);

    const fetchUserAddress = async () => {
        try {
            const response = await axios.get('/api/address/addresses', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (response.data.address) {
                const addressData = response.data.address;
                setUserAddress(addressData);
                setFormData({
                    alias: addressData.alias,
                    first_name: addressData.first_name,
                    last_name: addressData.last_name,
                    address: addressData.address,
                    address_2: addressData.address_2 || '',
                    postal_code: addressData.postal_code,
                    city: addressData.city,
                    country: addressData.country,
                    phone: addressData.phone || ''
                });
            }
        } catch (error) {
            console.error("Erreur lors de la récupération de l'adresse:", error);
        }
    };

    useEffect(() => {
        const isValid = !!(formData.first_name && formData.last_name && formData.address && formData.postal_code && formData.city && formData.country && formData.phone);
        setIsFormValid(isValid);
    }, [formData]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckout = async () => {
        if (!isAuthenticated) {
            navigate("/auth");
            return;
        }

        if (!isFormValid) {
            alert("Tous les champs obligatoires de l'adresse doivent être remplis.");
            return;
        }

        try {
            setNotification("Mise à jour de l'adresse en cours...");
            await updateAddress();
            setNotification("Adresse mise à jour avec succès.");
        } catch (error) {
            setNotification("Erreur lors de la mise à jour de l'adresse.");
            console.error(error);
        }

        const stripe = await stripePromise;

        if (!stripe) {
            console.error("Stripe n'a pas été chargé correctement.");
            return;
        }

        const response = await fetch('http://localhost:3001/create-checkout-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ cartItems }),
        });

        const session = await response.json();

        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    const updateAddress = async () => {
        try {
            console.log("Données envoyées pour mise à jour:", formData);
            await axios.put(
                '/api/address/addresses',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'adresse :", error);
            throw error;
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <Header />

            {/* Container principal, flex-wrap pour les écrans mobiles */}
            <div className="container mx-auto flex-grow px-4 py-16 flex flex-col lg:flex-row lg:gap-8 lg:items-start">

                {/* Section des produits */}
                <div className="w-full lg:w-2/3 mb-6 lg:mb-0">
                    <h1 className="text-3xl font-bold mb-4">Votre Panier</h1>

                    {cartItems.length === 0 ? (
                        <div className="bg-white rounded-lg shadow-md p-6 text-center">
                            <ShoppingBag className="mx-auto mb-4 text-gray-400" size={64}/>
                            <p className="text-xl text-gray-600">Votre panier est vide.</p>
                            <Link to="/allproductspage">
                                <button
                                    className="mt-4 px-6 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-300">
                                    Commencez vos achats
                                </button>
                            </Link>
                        </div>
                    ) : (
                        <div className="bg-white rounded-lg shadow-md overflow-hidden space-y-4">
                            {cartItems.map((item) => (
                                <div key={item.id}
                                     className="flex items-center gap-4 p-2 border-b border-gray-200 last:border-b-0">
                                    <img
                                        src={item.image}
                                        alt={item.title}
                                        className="w-20 h-20 object-cover rounded-md"
                                    />
                                    <div className="flex-1 text-sm">
                                        <h3 className="font-semibold text-md">{item.title}</h3>
                                        <p className="text-gray-600">{item.price.toFixed(2)} €</p>
                                        <div className="flex items-center gap-2 mt-2">
                                            <button
                                                className="p-1 rounded-full bg-gray-200 hover:bg-gray-300 transition duration-300"
                                                onClick={() => updateQuantity(item.id, Math.max(1, item.quantity - 1))}
                                            >
                                                <Minus size={16}/>
                                            </button>
                                            <input
                                                type="number"
                                                value={item.quantity}
                                                className="w-12 h-8 text-center bg-gray-100 rounded-md"
                                                onChange={(e) => updateQuantity(item.id, Math.max(1, Number(e.target.value)))}
                                                min="1"
                                            />
                                            <button
                                                className="p-1 rounded-full bg-gray-200 hover:bg-gray-300 transition duration-300"
                                                onClick={() => updateQuantity(item.id, item.quantity + 1)}
                                            >
                                                <Plus size={16}/>
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="text-gray-500 hover:text-red-500 transition duration-300"
                                        onClick={() => removeFromCart(item.id)}
                                    >
                                        <Trash2 size={20}/>
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                    <br/> <br/> <br/>

                    <Link to="/allproductspage">
                        <button
                            className="mt-4 px-6 py-2 border border-gray-300 rounded-md hover:bg-gray-100 transition duration-300">
                            Continuer vos achats
                        </button>
                    </Link>
                </div>

                {/* Sidebar avec adresse et paiement */}
                <div className="w-full lg:w-1/3">
                    <div className="bg-white rounded-lg shadow-md p-4">
                        <h2 className="text-xl font-semibold mb-2">Adresse de livraison</h2>
                        <div className="flex items-center">
                            <input
                                type="text"
                                name="alias"
                                value={formData.alias}
                                onChange={handleInputChange}
                                placeholder="Alias"
                                className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                            />
                            <button
                                className="ml-2 p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition duration-300"
                                onClick={() => setIsAddressSectionOpen(!isAddressSectionOpen)}
                            >
                                <Edit3 size={20} className="text-gray-600"/>
                            </button>
                        </div>

                        <motion.div
                            initial={{height: 0, opacity: 0}}
                            animate={{height: isAddressSectionOpen ? "auto" : 0, opacity: isAddressSectionOpen ? 1 : 0}}
                            transition={{duration: 0.3}}
                            className="overflow-hidden"
                        >
                            <div className="mt-4">
                                <div className="grid grid-cols-1 gap-2">
                                    <input
                                        type="text"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleInputChange}
                                        placeholder="Prénom"
                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                                    />
                                    <input
                                        type="text"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleInputChange}
                                        placeholder="Nom"
                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                                    />
                                    <input
                                        type="text"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleInputChange}
                                        placeholder="Adresse"
                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                                    />
                                    <input
                                        type="text"
                                        name="address_2"
                                        value={formData.address_2}
                                        onChange={handleInputChange}
                                        placeholder="Complément d'adresse (facultatif)"
                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                                    />
                                    <input
                                        type="text"
                                        name="postal_code"
                                        value={formData.postal_code}
                                        onChange={handleInputChange}
                                        placeholder="Code postal"
                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                                    />
                                    <input
                                        type="text"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleInputChange}
                                        placeholder="Ville"
                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                                    />
                                    <input
                                        type="text"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleInputChange}
                                        placeholder="Pays"
                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                                    />
                                    <input
                                        type="text"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        placeholder="Téléphone"
                                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none"
                                    />
                                </div>
                            </div>
                        </motion.div>
                        <br/>
                        <h2 className="text-xl font-semibold mb-2">Récapitulatif</h2>
                        <div className="space-y-2">
                            <div className="flex justify-between">
                                <span>Sous-total</span>
                                <span>{subtotal.toFixed(2)} €</span>
                            </div>
                            <div className="flex justify-between">
                                <span>Livraison</span>
                                <span>{shipping.toFixed(2)} €</span>
                            </div>
                            <div className="flex justify-between font-bold text-lg">
                                <span>Total</span>
                                <span>{total.toFixed(2)} €</span>
                            </div>
                        </div>
                        <button
                            className={`w-full mt-4 px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-300 ${!isFormValid ? "opacity-50 cursor-not-allowed" : ""}`}
                            onClick={handleCheckout}
                            disabled={!isFormValid}
                        >
                            Procéder au paiement
                        </button>
                    </div>
                </div>
            </div>

            {notification && (
                <div className="fixed bottom-4 right-4 bg-blue-500 text-white py-2 px-4 rounded-md shadow-md">
                    {notification}
                </div>
            )}

            <Footer/>
        </div>
    );
}

import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const RefundPolicyPage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                <div className="w-3/4 mx-auto bg-gray-100 p-8 rounded-lg shadow-lg font-bold font-binaria">
                    <h1 className="text-4xl font-bold mb-8">Politique de Remboursement</h1>

                    {/* Introduction */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Généralités</h2>
                        <p>
                            Chez <strong>Add-Last</strong>, nous nous engageons à offrir à nos clients des produits et services de qualité.
                            Si, pour une raison quelconque, vous n'êtes pas entièrement satisfait de votre achat, nous avons mis en place une
                            politique de remboursement pour vous offrir une expérience sans tracas.
                        </p>
                    </section>

                    {/* Conditions de remboursement */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Conditions de Remboursement</h2>
                        <p>
                            Nous acceptons les demandes de remboursement sous certaines conditions, en fonction du type de produit ou service acheté.
                            Les remboursements peuvent être demandés si les conditions suivantes sont remplies :
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Le produit a été retourné dans son état d'origine, non utilisé, et dans son emballage d'origine.</li>
                            <li>La demande de remboursement est faite dans les 30 jours suivant la date d'achat.</li>
                            <li>Le produit présente un défaut de fabrication ou ne correspond pas à la description.</li>
                        </ul>
                        <br/>
                        <p>
                            Veuillez noter que les produits en solde ou promotionnels ne sont pas éligibles au remboursement, sauf en cas de
                            défauts ou de non-conformité avec la description.
                        </p>
                    </section>

                    {/* Procédure de remboursement */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Comment demander un remboursement</h2>
                        <p>
                            Pour initier une demande de remboursement, veuillez suivre les étapes suivantes :
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Contactez notre service client à <a href="mailto:contact@add-last.com" className="text-red-500 hover:underline">contact@add-last.com</a> pour soumettre votre demande.</li>
                            <li>Incluez une preuve d'achat (comme un reçu ou un numéro de commande).</li>
                            <li>Expliquez la raison de votre demande de remboursement, avec des photos ou des preuves si le produit est défectueux.</li>
                            <li>Attendez notre confirmation avant de renvoyer le produit.</li>
                        </ul>
                        <br/>
                        <p>
                            Dès que nous aurons reçu et inspecté le produit retourné, nous vous enverrons un e-mail pour vous notifier
                            de la réception. Nous vous informerons également de l'approbation ou du rejet de votre remboursement.
                        </p>
                    </section>

                    {/* Délais de remboursement */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Délais de Remboursement</h2>
                        <p>
                            Si votre demande de remboursement est approuvée, le remboursement sera traité et un crédit sera automatiquement
                            appliqué à votre méthode de paiement originale dans un délai de 7 à 10 jours ouvrés. Selon votre établissement bancaire
                            ou la méthode de paiement utilisée, ce délai peut varier.
                        </p>
                    </section>

                    {/* Frais de retour */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Frais de Retour</h2>
                        <p>
                            Les frais d’expédition liés au retour des produits sont à la charge du client, sauf si le produit est défectueux ou
                            si nous avons fait une erreur dans votre commande. Dans ce cas, nous prendrons en charge les frais de retour.
                        </p>
                    </section>

                    {/* Exceptions */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Exceptions</h2>
                        <p>
                            Certains articles ne peuvent pas être retournés ni remboursés. Ces articles incluent :
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Les produits personnalisés ou faits sur mesure.</li>
                            <li>Les cartes cadeaux.</li>
                            <li>Les articles téléchargeables ou logiciels numériques.</li>
                            <li>Les articles en promotion ou déstockage.</li>
                        </ul>
                        <br/>
                        <p>
                            Si vous avez des questions concernant l'éligibilité d'un produit pour un remboursement, veuillez nous contacter avant de faire l'achat.
                        </p>
                    </section>

                    {/* Problèmes de remboursement */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Problèmes de Remboursement</h2>
                        <p>
                            Si vous n'avez pas reçu votre remboursement après le délai prévu, veuillez d'abord vérifier votre compte bancaire ou
                            contacter votre fournisseur de carte de crédit. Si le problème persiste, contactez-nous à l'adresse suivante :
                            <a href="mailto:contact@add-last.com" className="text-red-500 hover:underline"> contact@add-last.com</a>.
                        </p>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default RefundPolicyPage;
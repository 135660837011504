import React from 'react';

interface AddToCartButtonProps {
    onClick: () => void;
    label: string; // Ajout d'une prop pour le texte du bouton
}

const AddToCartButton: React.FC<AddToCartButtonProps> = ({ onClick, label }) => {
    return (
        <div
            className="button"
            onClick={onClick}
            style={{
                width: '150px',
                height: '45px',
                backgroundColor: '#222',
                position: 'relative',
                textAlign: 'center',
                borderRadius: '0.45em',
                fontFamily: 'Arial',
                transition: 'background 0.3s',
                cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
                const button = e.currentTarget;
                button.style.backgroundColor = 'rgb(226,85,53)';
                const text = button.querySelector('.text') as HTMLElement;
                const icon = button.querySelector('.icon') as HTMLElement;
                text.style.top = '-100%';
                icon.style.top = '0';
            }}
            onMouseLeave={(e) => {
                const button = e.currentTarget;
                button.style.backgroundColor = '#222';
                const text = button.querySelector('.text') as HTMLElement;
                const icon = button.querySelector('.icon') as HTMLElement;
                text.style.top = '0';
                icon.style.top = '100%';
            }}
        >
            <div className="button-wrapper font-binaria" style={{ overflow: 'hidden', position: 'absolute', width: '100%', height: '100%', left: 0 }}>
                <div
                    className="text"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: 0,
                        transition: 'top 0.5s',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        color: '#fff',
                    }}
                >
                    {label} {/* Afficher le texte du bouton */}
                </div>
                <span
                    className="icon"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        top: '100%',
                        transition: 'top 0.5s',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        left: 0,
                        color: '#fff',
                    }}
                >
                    <svg viewBox="0 0 16 16" className="bi bi-cart2" fill="currentColor" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
                    </svg>
                </span>
            </div>
        </div>
    );
};

export default AddToCartButton;

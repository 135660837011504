import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import BottomNavigation from "../../components/BottomNavigation";

const InformationsPage = () => {
    const [formData, setFormData] = useState({
        title: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        newPassword: '',
        dateOfBirth: '',
        subscribeNewsletter: false,
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();

    // Récupérer les informations de l'utilisateur au montage
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setErrorMessage('Token non trouvé, veuillez vous reconnecter.');
                    return;
                }

                const response = await axios.get('/api/auth/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const user = response.data.user;
                setFormData({
                    title: user.title,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    email: user.email,
                    password: '', // Vide pour la sécurité
                    newPassword: '',
                    dateOfBirth: user.date_of_birth ? user.date_of_birth.split('T')[0] : '', // Formatage initial pour l'affichage
                    subscribeNewsletter: user.subscribeNewsletter,
                });
            } catch (error) {
                setErrorMessage('Erreur lors de la récupération des informations.');
                console.error(error);
            }
        };

        fetchUserInfo();
    }, []);

    // Gérer les changements dans le formulaire
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: (e.target as HTMLInputElement).checked,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    // Vérification du format de la date avant de soumettre
    const validateDateOfBirth = (date: string) => {
        // Regex pour vérifier le format YYYY-MM-DD
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        return datePattern.test(date);
    };

    // Soumettre les modifications
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        // Validation de la date de naissance
        if (!validateDateOfBirth(formData.dateOfBirth)) {
            setErrorMessage('La date de naissance doit être au format YYYY-MM-DD.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setErrorMessage('Token non trouvé, veuillez vous reconnecter.');
                return;
            }

            const response = await axios.put(
                '/api/auth/update',
                {
                    first_name: formData.firstName,
                    last_name: formData.lastName,
                    email: formData.email,
                    password: formData.password, // Mot de passe actuel pour vérification
                    newPassword: formData.newPassword, // Nouveau mot de passe optionnel
                    date_of_birth: formData.dateOfBirth,
                    subscribeNewsletter: formData.subscribeNewsletter,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setSuccessMessage('Informations mises à jour avec succès.');
            setFormData({ ...formData, password: '', newPassword: '' }); // Réinitialiser les champs de mot de passe
        } catch (error) {
            const err = error as AxiosError;
            if (err.response && err.response.data) {
                setErrorMessage((err.response.data as any).message || 'Erreur lors de la mise à jour.');
            } else {
                setErrorMessage('Erreur lors de la mise à jour des informations.');
            }
        }
    };

    return (
        <div className="flex flex-col min-h-screen font-binaria">
            <Header />
            <main className="flex-grow p-8">
                <h1 className="text-4xl font-bold text-center mb-8">Vos informations personnelles</h1>
                <form className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto" onSubmit={handleSubmit}>
                    {/* Titre */}
                    <div className="mb-6">
                        <label className="block text-gray-700 text-lg font-semibold mb-2">Titre</label>
                        <div className="flex items-center space-x-6">
                            <label className="flex items-center space-x-2">
                                <input
                                    type="radio"
                                    name="title"
                                    value="M"
                                    checked={formData.title === 'M'}
                                    onChange={handleChange}
                                />
                                <span>M</span>
                            </label>
                            <label className="flex items-center space-x-2">
                                <input
                                    type="radio"
                                    name="title"
                                    value="Mme"
                                    checked={formData.title === 'Mme'}
                                    onChange={handleChange}
                                />
                                <span>Mme</span>
                            </label>
                        </div>
                    </div>

                    {/* Prénom */}
                    <div className="mb-6">
                        <label className="block text-gray-700 text-lg font-semibold mb-2">Prénom</label>
                        <input
                            type="text"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Votre prénom"
                        />
                    </div>

                    {/* Nom */}
                    <div className="mb-6">
                        <label className="block text-gray-700 text-lg font-semibold mb-2">Nom</label>
                        <input
                            type="text"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Votre nom"
                        />
                    </div>

                    {/* E-mail */}
                    <div className="mb-6">
                        <label className="block text-gray-700 text-lg font-semibold mb-2">E-mail</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Votre adresse e-mail"
                        />
                    </div>

                    {/* Mot de passe actuel */}
                    <div className="mb-6">
                        <label className="block text-gray-700 text-lg font-semibold mb-2">Mot de passe actuel</label>
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Votre mot de passe"
                        />
                    </div>

                    {/* Nouveau mot de passe */}
                    <div className="mb-6">
                        <label className="block text-gray-700 text-lg font-semibold mb-2">Nouveau mot de passe
                            (optionnel)</label>
                        <input
                            type="password"
                            name="newPassword"
                            value={formData.newPassword}
                            onChange={handleChange}
                            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
                            placeholder="Votre nouveau mot de passe"
                        />
                    </div>

                    {/* Date de naissance */}
                    <div className="flex flex-col mb-6">
                        <label htmlFor="dateOfBirth" className="font-binaria text-lg font-semibold mb-2">
                            Date de naissance
                        </label>
                        <input
                            type="date"
                            name="dateOfBirth"
                            value={formData.dateOfBirth}
                            onChange={handleChange}
                            className="font-binaria px-4 py-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    {/* Checkbox - Newsletter */}
                    <div className="mb-6">
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                name="subscribeNewsletter"
                                checked={formData.subscribeNewsletter}
                                onChange={handleChange}
                                className="form-checkbox"
                            />
                            <span>Recevoir notre newsletter</span>
                        </label>
                    </div>

                    {/* Message d'erreur et de succès */}
                    {errorMessage && <div className="text-red-500 mb-6 text-center">{errorMessage}</div>}
                    {successMessage && <div className="text-green-500 mb-6 text-center">{successMessage}</div>}

                    {/* Bouton Enregistrer */}
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="bg-orange-500 text-white font-semibold px-6 py-3 rounded-md hover:bg-orange-600 transition-all"
                        >
                            ENREGISTRER
                        </button>
                    </div>
                </form>
                <br/><br/>
                <BottomNavigation/>
            </main>
            <Footer/>
        </div>
    );
};

export default InformationsPage;

import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const TermsOfUsePage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                <div className="w-3/4 mx-auto bg-gray-100 p-8 rounded-lg shadow-lg font-bold font-binaria">
                    <h1 className="text-4xl font-bold mb-8">Conditions Générales d'Utilisation et de Vente</h1>

                    {/* Préambule */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Préambule</h2>
                        <p>
                            Toute commande effectuée sur le site Add-Last suppose l'acceptation préalable et sans restriction des présentes
                            Conditions Générales de Vente (CGV), applicables à toutes les ventes conclues avec les consommateurs sur le site Add-Last.
                            Le client est réputé les accepter sans réserve, faute de quoi sa commande ne sera pas validée.
                        </p>
                        <p>
                            En passant commande sur le site, le client atteste être une personne physique majeure agissant pour ses besoins
                            personnels; il reconnaît avoir la pleine capacité à s'engager au titre des présentes lorsqu'il effectue des transactions
                            sur Add-Last.
                        </p>
                        <p>
                            Les CGV peuvent être modifiées à tout moment et sans préavis par le vendeur. Les modifications seront applicables
                            dès leur mise en ligne.
                        </p>
                        <p>
                            Add-Last s’efforce de fournir des informations les plus précises possibles sur le site. Cependant, il ne pourra être tenu
                            responsable des inexactitudes, omissions, ou carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires.
                        </p>
                    </section>

                    {/* Identité du Vendeur */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Identité du Vendeur</h2>
                        <p>
                            Add-Last est un service de Add-Last SAS. Siège social :<br />
                            44 avenue Barbes, 93420 Villepinte
                        </p>
                        <p>
                            Hébergeur : Le site Add-Last est hébergé par la société OVH.<br />
                            RCS Lille Métropole 424 761 419 00045<br />
                            2 rue Kellermann, 59100 Roubaix - France<br />
                            <a href="https://www.ovh.com" className="text-red-500 hover:underline">OVH</a>
                        </p>
                    </section>

                    {/* Contact */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Contact</h2>
                        <p>
                            Pour toute question ou demande d'information concernant le site, l'utilisateur peut contacter l'éditeur à l'adresse e-mail suivante :
                            <a href="mailto:atrewind404@gmail.com" className="text-red-500 hover:underline"> atrewind404@gmail.com</a>.
                        </p>
                    </section>

                    {/* Catalogue ou Boutique en Ligne */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Catalogue ou Boutique en Ligne</h2>
                        <p>
                            Les caractéristiques essentielles des produits sont indiquées dans la fiche produit. Le vendeur se réserve le droit de
                            modifier, améliorer, ou réactualiser les produits sans préavis. Les produits sont décrits et présentés avec la plus grande exactitude,
                            mais les photographies n'ont pas de valeur contractuelle.
                        </p>
                        <p>
                            Les produits sont proposés dans la limite des stocks disponibles. En cas d'indisponibilité exceptionnelle, le vendeur s'engage à
                            informer le client et à lui apporter toute précision complémentaire pour le bon déroulement de sa commande.
                        </p>
                    </section>

                    {/* Prix */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Prix</h2>
                        <p>
                            Les prix figurant sur le site sont indiqués en euros, TVA comprise, hors frais de livraison. Le vendeur se réserve le droit de
                            modifier ses prix à tout moment, en les publiant en ligne. Seuls les tarifs en vigueur au moment de la commande s'appliquent.
                        </p>
                        <p>
                            Le montant des frais de livraison sera facturé en supplément et indiqué avant validation de la commande par le client.
                        </p>
                    </section>

                    {/* Commandes */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Commandes</h2>
                        <p>
                            Le vendeur peut actualiser ses fiches ou retirer des produits de la vente à tout moment. Les commandes ne sont valables que dans la
                            limite des stocks disponibles chez nos fournisseurs.
                        </p>
                        <p>
                            En cas d'indisponibilité, le vendeur contactera le client pour annuler la commande du produit concerné et rembourser le prix correspondant.
                        </p>
                    </section>

                    {/* Confirmation de la Commande */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Confirmation de la Commande</h2>
                        <p>
                            La confirmation de la commande implique une obligation de paiement. Le client devra fournir une adresse de livraison valide.
                            La commande est confirmée par l'envoi d'un e-mail généré automatiquement à l'adresse fournie lors de la création du compte client.
                        </p>
                    </section>

                    {/* Paiement */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Paiement</h2>
                        <p>
                            Le paiement s'effectue lors de la validation finale de la commande. Le client garantit qu'il dispose des autorisations nécessaires
                            pour utiliser sa carte bancaire. Le vendeur peut demander des justificatifs en cas de doute ou de suspicion de fraude.
                        </p>
                        <p>
                            En cas de refus d'autorisation de paiement par la banque ou de non-paiement, le vendeur se réserve le droit de suspendre ou annuler
                            la commande.
                        </p>
                    </section>

                    {/* Livraison */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Livraison</h2>
                        <p>
                            La livraison s'effectue après confirmation de paiement. Les délais de livraison sont indiqués lors du processus de commande.
                            En cas de retard, le client pourra annuler la commande et demander un remboursement.
                        </p>
                    </section>

                    {/* Garantie */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Garantie</h2>
                        <p>
                            Les produits sont garantis par le constructeur ou par le vendeur pendant 2 ans. En cas de défaut, le client peut demander
                            une réparation ou un remplacement. Certains produits ne sont pas couverts par la garantie, notamment les logiciels et les consommables.
                        </p>
                    </section>

                    {/* Droit de Rétractation */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Droit de Rétractation</h2>
                        <p>
                            Le client dispose d'un droit de rétractation de 14 jours à compter de la réception de la commande. Les produits retournés
                            doivent être dans leur état d'origine, non utilisés, et dans leur emballage complet.
                        </p>
                    </section>

                    {/* Limitation de Responsabilité */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Limitation de Responsabilité</h2>
                        <p>
                            Le vendeur ne peut être tenu responsable des dommages causés par une utilisation incorrecte des produits, des pertes de données ou
                            de la destruction des équipements pendant une intervention technique.
                        </p>
                    </section>

                    {/* Contact et Réclamations */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Contact et Réclamations</h2>
                        <p>
                            Pour toute question, demande d'assistance ou réclamation, le client peut contacter notre service client par e-mail à
                            <a href="mailto:atrewind404@gmail.com" className="text-red-500 hover:underline"> atrewind404@gmail.com</a>.
                        </p>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default TermsOfUsePage;
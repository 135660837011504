import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import BottomNavigation from "../../components/BottomNavigation";

const PersonalDataPage = () => {
    return (
        <div className="flex flex-col min-h-screen font-binaria">
            <Header />
            <main className="flex-grow px-8 py-8">
                {/* Titre de la page */}
                <h1 className="text-4xl font-bold text-center mb-8">GDPR - Personal data</h1>

                {/* Contenu de la page */}
                <div className="bg-white shadow-md rounded-lg p-6 max-w-lg mx-auto">
                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">Accès à mes données</h2>
                    <p className="text-gray-600 mb-4">
                        À tout moment, vous avez le droit de récupérer les données que vous avez fournies sur notre site.
                        Cliquez sur <strong>"Exporter mes données"</strong> pour télécharger automatiquement une copie
                        de vos données personnelles dans un fichier PDF ou CSV.
                    </p>

                    {/* Boutons d'export */}
                    <div className="flex justify-center space-x-4 mb-6">
                        <button
                            className="bg-[rgb(226,85,53)] text-white font-semibold px-6 py-3 rounded-md hover:bg-opacity-90 transition-all"
                        >
                            EXPORTER MES DONNÉES EN PDF
                        </button>
                        <button
                            className="bg-[rgb(226,85,53)] text-white font-semibold px-6 py-3 rounded-md hover:bg-opacity-90 transition-all"
                        >
                            EXPORTER MES DONNÉES EN CSV
                        </button>
                    </div>

                    <h2 className="text-2xl font-semibold mb-4 text-gray-700">Demande de rectification et d'effacement</h2>
                    <p className="text-gray-600">
                        Vous avez le droit de modifier toutes les informations personnelles figurant sur la page
                        <Link to="/account" className="text-[rgb(226,85,53)] hover:underline"> "Mon compte"</Link>.
                        Pour toute autre demande que vous pourriez avoir concernant la rectification et/ou l'effacement
                        de vos données personnelles, veuillez nous contacter via notre
                        <Link to="/contact" className="text-[rgb(226,85,53)] hover:underline"> formulaire de contact</Link>.
                        Nous examinerons votre demande et vous répondrons dans les plus brefs délais.
                    </p>
                </div>

                {/* Navigation en bas de la page */}
                <br/><br/>
                <BottomNavigation />
            </main>

            <Footer />
        </div>
    );
};

export default PersonalDataPage;

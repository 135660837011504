import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface RevenueChartProps {
    orders: { created_at: string; total_price: number | string }[];
}

const RevenueChart: React.FC<RevenueChartProps> = ({ orders }) => {
    const data = orders.map((order) => ({
        x: new Date(order.created_at).getTime(), // Convertir la date en timestamp pour Highcharts
        y: Number(order.total_price), // Convertir le prix en nombre
    }));

    const options = {
        chart: {
            type: 'area', // Type de graphique
            zoomType: 'x', // Permet le zoom sur l'axe des X
            backgroundColor: '#f4f4f4', // Couleur de fond douce
            plotBorderWidth: 1,
            plotBorderColor: '#ccc',
        },
        title: {
            text: 'Chiffre d\'affaires en fonction du temps',
            style: {
                color: '#333',
                fontSize: '18px',
                fontWeight: 'bold',
            },
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: 'Date',
            },
            labels: {
                format: '{value:%e %b %Y}', // Format des dates
            },
            gridLineWidth: 1, // Ajout de lignes de grille
            gridLineColor: '#e6e6e6',
        },
        yAxis: {
            title: {
                text: 'Chiffre d\'affaires (€)',
            },
            gridLineWidth: 1, // Ajout de lignes de grille
            gridLineColor: '#e6e6e6',
        },
        tooltip: {
            shared: true,
            valueSuffix: ' €',
        },
        legend: {
            enabled: false,
        },
        series: [
            {
                name: 'Chiffre d\'affaires',
                data,
                type: 'area',
                color: {
                    linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 },
                    stops: [
                        [0, '#6a5acd'], // Blue
                        [1, '#8a2be2'], // Purple
                    ],
                },
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, 'rgba(106, 90, 205, 0.5)'], // Light blue semi-transparent
                        [1, 'rgba(138, 43, 226, 0.2)'], // Purple semi-transparent
                    ],
                },
                lineWidth: 2,
                marker: {
                    enabled: true,
                    radius: 3,
                    fillColor: '#6a5acd',  // Blue marker
                    lineWidth: 1,
                    lineColor: '#8a2be2',  // Purple border
                },
                shadow: true,
            },
        ],
        credits: {
            enabled: false, // Désactive le logo Highcharts en bas du graphique
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default RevenueChart;
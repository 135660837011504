import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import ProductCardV2 from "../components/ProductCardV2";
import Footer from "../components/Footer";
import { getAllProducts } from '../services/api'; // Fonction pour récupérer les produits
import GradientButton from "../components/HomePage/GradientButton";

const AllProductsPage = () => {
    const [products, setProducts] = useState<any[]>([]); // Stocke les produits
    const [filteredProducts, setFilteredProducts] = useState<any[]>([]); // Produits filtrés pour l'affichage
    const [sortType, setSortType] = useState<string>(''); // Type de tri
    const [filterSize, setFilterSize] = useState<string>(''); // Filtre de taille
    const [filterBrand, setFilterBrand] = useState<string>(''); // Filtre de marque

    useEffect(() => {
        fetchProducts();
    }, []);

    // Récupérer les produits depuis l'API
    const fetchProducts = async () => {
        try {
            const response = await getAllProducts();
            setProducts(response.data);
            setFilteredProducts(response.data); // Afficher tous les produits au départ
        } catch (error) {
            console.error('Erreur lors de la récupération des produits :', error);
        }
    };

    // Fonction de tri par prix, likes, etc.
    const sortProducts = (type: string) => {
        const sorted = [...filteredProducts].sort((a, b) => {
            if (type === 'price-asc') return a.price - b.price;
            if (type === 'price-desc') return b.price - a.price;
            if (type === 'likes-asc') return a.likes_current - b.likes_current;
            if (type === 'likes-desc') return b.likes_current - a.likes_current;
            return 0;
        });
        setFilteredProducts(sorted);
        setSortType(type);
    };

    // Filtrer les produits par taille ou marque
    const filterProducts = () => {
        let updatedProducts = [...products];

        if (filterSize) {
            updatedProducts = updatedProducts.filter(product =>
                product.sizes_stock && Object.keys(product.sizes_stock).includes(filterSize)
            );
        }

        if (filterBrand) {
            updatedProducts = updatedProducts.filter(product => product.brand === filterBrand);
        }

        setFilteredProducts(updatedProducts);
    };

    useEffect(() => {
        filterProducts();
    }, [filterSize, filterBrand]);

    return (
        <div>
            <Header />

            <main className="px-4 py-4">
                {/* Boutons de tri et filtres */}
                <div className="flex flex-col md:flex-row justify-between mb-8 space-y-4 md:space-y-0 md:space-x-4">
                    <div className="flex space-x-4">
                        <button
                            onClick={() => sortProducts('price-asc')}
                            className={`px-4 py-2 rounded-md ${sortType === 'price-asc' ? 'bg-gray-800 text-white' : 'bg-gray-300 text-gray-700'}`}
                        >
                            Prix croissant
                        </button>
                        <button
                            onClick={() => sortProducts('price-desc')}
                            className={`px-4 py-2 rounded-md ${sortType === 'price-desc' ? 'bg-gray-800 text-white' : 'bg-gray-300 text-gray-700'}`}
                        >
                            Prix décroissant
                        </button>
                        <button
                            onClick={() => sortProducts('likes-asc')}
                            className={`px-4 py-2 rounded-md ${sortType === 'likes-asc' ? 'bg-gray-800 text-white' : 'bg-gray-300 text-gray-700'}`}
                        >
                            Likes croissants
                        </button>
                        <button
                            onClick={() => sortProducts('likes-desc')}
                            className={`px-4 py-2 rounded-md ${sortType === 'likes-desc' ? 'bg-gray-800 text-white' : 'bg-gray-300 text-gray-700'}`}
                        >
                            Likes décroissants
                        </button>
                    </div>

                    <div className="flex space-x-4">
                        {/* Filtre par taille */}
                        <select
                            value={filterSize}
                            onChange={(e) => setFilterSize(e.target.value)}
                            className="px-4 py-2 rounded-md bg-gray-300 text-gray-700"
                        >
                            <option value="">Toutes les tailles</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                            <option value="36">36</option>
                            <option value="37">37</option>
                            <option value="38">38</option>
                        </select>

                        {/* Filtre par marque */}
                        <select
                            value={filterBrand}
                            onChange={(e) => setFilterBrand(e.target.value)}
                            className="px-4 py-2 rounded-md bg-gray-300 text-gray-700"
                        >
                            <option value="">Toutes les marques</option>
                            <option value="NIKE">Nike</option>
                            <option value="ADIDAS">Adidas</option>
                            <option value="NEW BALANCE">New Balance</option>
                            <option value="SALOMON">Salomon</option>
                            <option value="JORDAN">Jordan</option>
                        </select>
                    </div>
                </div>

                {/* Grille des produits */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {filteredProducts.length > 0 ? (
                        filteredProducts.map((product) => (
                            <ProductCardV2
                                key={product.id}  // S'assurer que chaque produit a un ID unique
                                id={product.id}  // Transmettre l'ID du produit
                                imageSrc={product.image_1}
                                title={product.name}
                                price={product.price}
                                likes={product.likes_current}
                                likesObjective={product.likes_target}
                                showAdditionalInfo={true}
                            />
                        ))
                    ) : (
                        <p>Aucun produit disponible.</p>
                    )}
                </div>

                <br />

            </main>

            <Footer />
        </div>
    );
};

export default AllProductsPage;

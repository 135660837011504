import React, { useState, useEffect } from 'react';
import { createProduct, getAllProducts, updateProduct } from '../services/api';
import ProductEditForm from '../components/AdminPage/ProductEditForm';
import WordPullUp from "../components/magicui/word-pull-up";
import HeroProductSelector from "../components/AdminPage/HeroProductSelector";
import axios from 'axios';
import UserList from "../components/AdminPage/UserList";
import OrderList from '../components/AdminPage/OrderList'; // Importer OrderList
import { Edit, ArrowUp, ArrowDown } from 'lucide-react'; // Icônes de tri

interface Product {
    id: number;
    name: string;
    brand: string;
    price: number;
    description: string;
    details: string;
    likes_current: number;
    likes_target: number;
    sizes_stock: { [key: string]: number };
    image_1: string;
    image_2: string;
    image_3: string;
    image_4: string;
    image_5: string;
    hero_title?: string;
    hero_description?: string;
    hero_image?: string;
    is_hero: boolean;
}

interface FormData {
    name: string;
    brand: string;
    price: string | number;
    description: string;
    details: string;
    likes_current: number;
    likes_target: number;
    sizes_type: 'unique' | 'clothing' | 'shoes';
    sizes_stock: { [key: string]: number };
    image_1: string;
    image_2: string;
    image_3: string;
    image_4: string;
    image_5: string;
    hero_title?: string;
    hero_description?: string;
    hero_image?: string;
}

interface AdminOrder {
    id: number;
    user_id: number;
    created_at: string;
    total_price: number | string;
    status: string;
    order_details: any[]; // Détails des produits
    stripe_payment_intent: string;
    address_id: number;  // Ajout de l'address_id ici pour correspondre à Order
    address: {
        first_name: string;
        last_name: string;
        address: string;
        postal_code: string;
        city: string;
        country: string;
    };
    user: {
        email: string;
        first_name: string;
        last_name: string;
    };
}

const AdminPage = () => {
    const [products, setProducts] = useState<Product[]>([]);
    const [orders, setOrders] = useState<AdminOrder[]>([]);
    const [formData, setFormData] = useState<FormData>({
        name: '',
        brand: '',
        price: '',
        description: '',
        details: '',
        likes_current: 0,
        likes_target: 0,
        sizes_type: 'clothing',
        sizes_stock: {},
        image_1: '',
        image_2: '',
        image_3: '',
        image_4: '',
        image_5: '',
        hero_title: '',
        hero_description: '',
        hero_image: ''
    });
    const [heroProduct, setHeroProduct] = useState<Product | null>(null);
    const [activeSection, setActiveSection] = useState<string>('products');
    const [productSubSection, setProductSubSection] = useState<string>('create');
    const [editingProduct, setEditingProduct] = useState<Product | null>(null);
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        fetchProducts();
        fetchHeroProduct();
        fetchOrders();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await getAllProducts();
            setProducts(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des produits :', error);
        }
    };

    const fetchOrders = async () => {
        try {
            const response = await axios.get('/api/orders');
            setOrders(response.data.orders);
        } catch (error) {
            console.error('Erreur lors de la récupération des commandes :', error);
        }
    };

    const fetchHeroProduct = async () => {
        try {
            const response = await axios.get('/api/products/hero-product');
            setHeroProduct(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération du produit mis en avant :', error);
        }
    };

    // Fonction pour mettre à jour le statut d'une commande
    const updateOrderStatus = async (orderId: number, newStatus: string) => {
        try {
            await axios.put(`/api/orders/${orderId}/status`, { status: newStatus });
            fetchOrders(); // Recharge les commandes après la mise à jour
        } catch (error) {
            console.error('Erreur lors de la mise à jour du statut de la commande :', error);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: name === 'price' ? parseFloat(value) : value,
        }));
    };

    const handleSizesTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newSizesType = e.target.value as 'unique' | 'clothing' | 'shoes';
        setFormData((prevData) => ({
            ...prevData,
            sizes_type: newSizesType,
            sizes_stock: {},
        }));
    };

    const handleSizesStockChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            sizes_stock: {
                ...prevData.sizes_stock,
                [name]: parseInt(value) || 0,
            },
        }));
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editingProduct) {
            try {
                await updateProduct(editingProduct.id, formData);
                fetchProducts();
                setEditingProduct(null);
                resetFormData();
            } catch (error) {
                console.error('Erreur lors de la mise à jour du produit :', error);
            }
        } else {
            try {
                await createProduct(formData);
                fetchProducts();
                resetFormData();
            } catch (error) {
                console.error('Erreur lors de la création du produit :', error);
            }
        }
    };

    const resetFormData = () => {
        setFormData({
            name: '',
            brand: '',
            price: '',
            description: '',
            details: '',
            likes_current: 0,
            likes_target: 0,
            sizes_type: 'clothing',
            sizes_stock: {},
            image_1: '',
            image_2: '',
            image_3: '',
            image_4: '',
            image_5: '',
            hero_title: '',
            hero_description: '',
            hero_image: ''
        });
    };

    const handleEdit = (product: Product) => {
        setEditingProduct(product);
        setFormData({
            name: product.name,
            brand: product.brand,
            price: product.price,
            description: product.description,
            details: product.details,
            likes_current: product.likes_current,
            likes_target: product.likes_target,
            sizes_type: 'clothing',
            sizes_stock: product.sizes_stock,
            image_1: product.image_1,
            image_2: product.image_2,
            image_3: product.image_3,
            image_4: product.image_4,
            image_5: product.image_5,
            hero_title: product.hero_title,
            hero_description: product.hero_description,
            hero_image: product.hero_image
        });
        setProductSubSection('edit');
    };

    const handleSetHeroProduct = async (productId: number) => {
        try {
            await axios.put(`/api/hero-product/${productId}`);
            fetchHeroProduct();
        } catch (error) {
            console.error('Erreur lors de la mise en avant du produit :', error);
        }
    };

    const handleSort = (column: keyof Product) => {
        const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortColumn(column);
        setSortOrder(newOrder);

        const sortedProducts = [...products].sort((a, b) => {
            let valA, valB;
            if (typeof a[column] === 'string' && typeof b[column] === 'string') {
                valA = (a[column] as string).toLowerCase();
                valB = (b[column] as string).toLowerCase();
            } else {
                valA = a[column];
                valB = b[column];
            }
            // @ts-ignore
            if (valA < valB) return newOrder === 'asc' ? -1 : 1;
            // @ts-ignore
            if (valA > valB) return newOrder === 'asc' ? 1 : -1;
            return 0;
        });

        setProducts(sortedProducts);
    };

    const renderHeroSection = () => {
        if (products.length === 0) {
            return <p>Chargement des produits...</p>;
        }

        return (
            <>
                <WordPullUp className="text-3xl font-bold tracking-[-0.02em] text-black md:text-5xl md:leading-[5rem] justify-start" words="Mettre un produit en avant" />
                <HeroProductSelector products={products} onHeroSelection={handleSetHeroProduct} />
            </>
        );
    };

    const renderProductSection = () => {
        switch (productSubSection) {
            case 'create':
                return (
                    <>
                        <WordPullUp className="text-3xl font-bold tracking-[-0.02em] text-black md:text-5xl md:leading-[5rem] justify-start" words="Créer un produit" />
                        <ProductEditForm
                            formData={formData}
                            setFormData={setFormData}
                            handleSubmit={handleSubmit}
                            handleInputChange={handleInputChange}
                            handleSizesTypeChange={handleSizesTypeChange}
                            handleSizesStockChange={handleSizesStockChange}
                        />
                    </>
                );
            case 'list':
                return (
                    <>
                        <WordPullUp className="text-3xl font-bold tracking-[-0.02em] text-black md:text-5xl md:leading-[5rem] justify-start" words="Liste des produits" />
                        <table className="min-w-full bg-white border border-gray-300 table-fixed">
                            <thead>
                            <tr>
                                <th className="py-2 px-4 border-b w-24">Image</th>
                                <th className="py-2 px-4 border-b w-48">
                                    Titre
                                    <button onClick={() => handleSort('name')} className="ml-2 float-right">
                                        {sortColumn === 'name' && sortOrder === 'asc' ? <ArrowUp /> : <ArrowDown />}
                                    </button>
                                </th>
                                <th className="py-2 px-4 border-b w-48">
                                    Marque
                                    <button onClick={() => handleSort('brand')} className="ml-2 float-right">
                                        {sortColumn === 'brand' && sortOrder === 'asc' ? <ArrowUp /> : <ArrowDown />}
                                    </button>
                                </th>
                                <th className="py-2 px-4 border-b w-32">
                                    Prix
                                    <button onClick={() => handleSort('price')} className="ml-2 float-right">
                                        {sortColumn === 'price' && sortOrder === 'asc' ? <ArrowUp /> : <ArrowDown />}
                                    </button>
                                </th>
                                <th className="py-2 px-4 border-b w-80">Description</th>
                                <th className="py-2 px-4 border-b w-40">
                                    Likes actuels / Objectif
                                    <button onClick={() => handleSort('likes_current')} className="ml-2 float-right">
                                        {sortColumn === 'likes_current' && sortOrder === 'asc' ? <ArrowUp /> : <ArrowDown />}
                                    </button>
                                </th>
                                <th className="py-2 px-4 border-b w-48">Produit mis en avant</th>
                                <th className="py-2 px-4 border-b w-32">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {products.map((product) => {
                                const percentageLikes = product.likes_target > 0
                                    ? ((product.likes_current / product.likes_target) * 100).toFixed(2)
                                    : '0';
                                return (
                                    <tr key={product.id} className="h-24">
                                        <td className="py-2 px-4 border-b">
                                            <img src={product.image_1} alt={product.name} className="w-16 h-16 object-cover rounded-md" />
                                        </td>
                                        <td className="py-2 px-4 border-b">{product.name}</td>
                                        <td className="py-2 px-4 border-b">{product.brand}</td>
                                        <td className="py-2 px-4 border-b">{product.price} €</td>
                                        <td className="py-2 px-4 border-b">
                                            {product.description.length > 100 ? `${product.description.substring(0, 100)}...` : product.description}
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            {product.likes_current} / {product.likes_target}
                                            <br />
                                            <small>{percentageLikes}%</small>
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            {product.hero_image ? (
                                                <img src={product.hero_image} alt="Produit mis en avant" className="w-16 h-16 object-cover rounded-md" />
                                            ) : (
                                                <span>Pas de produit mis en avant</span>
                                            )}
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <button onClick={() => handleEdit(product)} className="flex items-center text-blue-500 hover:underline">
                                                <Edit className="w-5 h-5 mr-2" />
                                                Modifier
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </>
                );
            case 'edit':
                return (
                    <>
                        <WordPullUp className="text-3xl font-bold tracking-[-0.02em] text-black md:text-5xl md:leading-[5rem] justify-start" words="Modifier un produit" />
                        <ProductEditForm
                            formData={formData}
                            setFormData={setFormData}
                            handleSubmit={handleSubmit}
                            handleInputChange={handleInputChange}
                            handleSizesTypeChange={handleSizesTypeChange}
                            handleSizesStockChange={handleSizesStockChange}
                        />
                    </>
                );
            case 'hero':
                return renderHeroSection();
            default:
                return <p>Sélectionnez une sous-section.</p>;
        }
    };

    const renderOrdersList = () => {
        return (
            // @ts-ignore
            <OrderList orders={orders} onUpdateStatus={updateOrderStatus} />
        );
    };

    const renderSection = () => {
        switch (activeSection) {
            case 'products':
                return (
                    <>
                        <div className="fixed top-0 left-64 right-0 bg-gray-100 border-b border-gray-300 z-10 py-4 flex justify-center space-x-4">
                            <button onClick={() => setProductSubSection('create')} className={`px-4 py-2 rounded ${productSubSection === 'create' ? 'bg-gray-600 text-white' : 'bg-gray-300'}`}>
                                Créer un produit
                            </button>
                            <button onClick={() => setProductSubSection('list')} className={`px-4 py-2 rounded ${productSubSection === 'list' ? 'bg-gray-600 text-white' : 'bg-gray-300'}`}>
                                Voir les produits
                            </button>
                            <button onClick={() => setProductSubSection('hero')} className={`px-4 py-2 rounded ${productSubSection === 'hero' ? 'bg-gray-600 text-white' : 'bg-gray-300'}`}>
                                Mettre un produit en avant
                            </button>
                        </div>
                        <div className="mt-20">{renderProductSection()}</div>
                    </>
                );
            case 'users':
                return (
                    <>
                        <UserList />
                    </>
                );
            case 'orders':
                return renderOrdersList();
            default:
                return <div className="text-xl">Sélectionnez une section.</div>;
        }
    };

    return (
        <div className="min-h-screen flex">
            <div className="fixed top-0 left-0 w-64 h-screen bg-gray-800 text-white p-6 space-y-6">
                <div className="text-2xl font-bold mb-4">Admin Dashboard</div>
                <button onClick={() => setActiveSection('products')} className={`w-full text-left py-2 px-4 rounded-md ${activeSection === 'products' ? 'bg-gray-600' : 'hover:bg-gray-700'}`}>
                    Gestion des produits
                </button>
                <button onClick={() => setActiveSection('users')} className={`w-full text-left py-2 px-4 rounded-md ${activeSection === 'users' ? 'bg-gray-600' : 'hover:bg-gray-700'}`}>
                    Gestion des utilisateurs
                </button>
                <button onClick={() => setActiveSection('orders')} className={`w-full text-left py-2 px-4 rounded-md ${activeSection === 'orders' ? 'bg-gray-600' : 'hover:bg-gray-700'}`}>
                    Gestion des commandes
                </button>
                <button onClick={() => window.location.href = '/'} className="w-full text-left py-2 px-4 mt-6 bg-blue-600 hover:bg-blue-700 rounded-md">
                    Retour au site
                </button>
            </div>
            <div className="flex-1 ml-64 p-6">
                {renderSection()}
            </div>
        </div>
    );
};

export default AdminPage;
import { ShoppingCart, Package, CreditCard, Truck, Headphones, RefreshCw } from "lucide-react"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { ReactNode } from "react"

// Typing for Button props
interface ButtonProps {
    children: ReactNode;
    size?: "sm" | "md" | "lg";
    [key: string]: any; // Accepts other props
}

// Typing for Card and FeatureCard props
interface CardProps {
    children: ReactNode;
    className?: string; // Optional className prop
}

interface FeatureCardProps {
    icon: ReactNode;
    title: string;
    description: string;
}

export default function AboutPage() {
    return (
        <>
            <Header />
            <div className="container mx-auto px-4 py-8 font-binaria">
                <br/>
                <h1 className="text-4xl font-bold text-center mb-8">À propos de Add-Last</h1>
                <br/>
                <p className="text-xl text-center mb-12 max-w-3xl mx-auto">
                    Add-Last est une plateforme de commerce en ligne innovante conçue pour offrir une expérience d'achat fluide et conviviale.
                    Parcourez, sélectionnez et achetez une large gamme de produits en toute simplicité et en toute confiance.
                </p>

                {/* Section des cartes de fonctionnalités */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
                    <FeatureCard
                        icon={<ShoppingCart className="h-10 w-10 text-primary" />}
                        title="Catalogue de Produits Étendu"
                        description="Parcourez notre large gamme de produits avec des descriptions détaillées, des images de haute qualité et des prix compétitifs."
                    />
                    <FeatureCard
                        icon={<Package className="h-10 w-10 text-primary" />}
                        title="Commandes Simples"
                        description="Ajoutez des articles à votre panier et profitez d'un processus de commande simple avec des options de paiement sécurisées."
                    />
                    <FeatureCard
                        icon={<CreditCard className="h-10 w-10 text-primary" />}
                        title="Paiements Sécurisés"
                        description="Achetez en toute confiance grâce à notre passerelle de paiement sécurisée, prenant en charge divers modes de paiement, y compris les cartes de crédit."
                    />
                    <FeatureCard
                        icon={<Truck className="h-10 w-10 text-primary" />}
                        title="Livraison Fiable"
                        description="Suivez vos commandes en ligne et recevez des mises à jour en temps réel sur l'état de la livraison. Frais de livraison et délais transparents."
                    />
                    <FeatureCard
                        icon={<Headphones className="h-10 w-10 text-primary" />}
                        title="Support Client"
                        description="Notre équipe de support dédiée est prête à vous aider pour toute question ou problème par email."
                    />
                    <FeatureCard
                        icon={<RefreshCw className="h-10 w-10 text-primary" />}
                        title="Retours Faciles"
                        description="Politique de retour sans tracas sous 14 jours pour les produits non utilisés dans leur emballage d'origine."
                    />
                </div>

                {/* Bouton d'appel à l'action */}
                <div className="text-center">
                    <Button size="lg">Commencez vos achats</Button>
                </div>
            </div>
            <Footer />
        </>
    )
}

// Composant Button avec typage
function Button({ children, size = "md", ...props }: ButtonProps) {
    const sizeClasses = {
        sm: "px-2 py-1 text-sm",
        md: "px-4 py-2",
        lg: "px-6 py-3 text-lg"
    }

    return (
        <button
            className={`bg-primary text-primary-foreground hover:bg-primary/90 rounded-md font-medium transition-colors ${sizeClasses[size]}`}
            {...props}
        >
            {children}
        </button>
    )
}

// Composant Card avec typage
function Card({ children, className }: CardProps) {
    return (
        <div className={`bg-card text-card-foreground rounded-lg border shadow-sm ${className}`}>
            {children}
        </div>
    )
}

// Composants pour le header et le contenu de la Card
function CardHeader({ children, className }: CardProps) {
    return <div className={`flex flex-col space-y-1.5 p-6 ${className}`}>{children}</div>
}

function CardTitle({ children, className }: CardProps) {
    return <h3 className={`text-2xl font-semibold leading-none tracking-tight ${className}`}>{children}</h3>
}

function CardContent({ children, className }: CardProps) {
    return <div className={`p-6 pt-0 ${className}`}>{children}</div>
}

function CardDescription({ children, className }: CardProps) {
    return <p className={`text-sm text-muted-foreground ${className}`}>{children}</p>
}

// Composant FeatureCard avec typage
function FeatureCard({ icon, title, description }: FeatureCardProps) {
    return (
        <Card>
            <CardHeader>
                <CardTitle className="flex items-center gap-4">
                    {icon}
                    <br/>
                    <span>{title}</span>
                </CardTitle>
            </CardHeader>
            <CardContent>
                <CardDescription>{description}</CardDescription>
            </CardContent>
        </Card>
    )
}

import React from "react";

interface GradientButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

export default function GradientButton({
                                           className,
                                           children,
                                           ...props
                                       }: GradientButtonProps) {
    return (
        <button
            className={`relative text-center cursor-pointer flex justify-center items-center rounded-lg font-binaria font-regular text-2xl text-white bg-gradient-to-r from-[rgb(228,105,76)] to-red-500 px-6 py-3 transition-all duration-300 ease-in-out transform hover:scale-105 ${className}`}
            {...props}
        >
            {/* Contenu du bouton */}
            <div className="relative z-10">{children}</div>
        </button>
    );
}
import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const LegalPage = () => {
    return (
        <div className="flex flex-col min-h-screen">
            <Header />
            <main className="flex-grow p-4">
                <div className="w-3/4 mx-auto bg-gray-100 p-8 rounded-lg shadow-lg font-bold font-binaria">
                    <h1 className="text-4xl font-bold mb-8">Mentions Légales et Politique de Confidentialité</h1>

                    {/* Mentions légales */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Mentions légales</h2>
                        <p><strong>Éditeur :</strong></p>
                        <p>Add-Last SAS</p>
                        <p><a href="https://add-last.com" className="text-red-500 hover:underline">Add-Last</a></p>
                        <p>44 avenue barbes, 93420 Villepinte</p>
                        <br/>
                        <p><strong>Gérance :</strong></p>
                        <p>Président : <a href="mailto:atrewind404@gmail.com" className="text-red-500 hover:underline">MERCIER Thibault</a></p>
                        <p>Directeur général : <a href="mailto:alexis.martineau.95@gmail.com" className="text-red-500 hover:underline">MARTINEAU Alexis</a></p>
                        <br/>
                        <p><strong>Hébergeur :</strong></p>
                        <p>Le site <a href="https://add-last.com" className="text-red-500 hover:underline">Add-Last</a> est hébergé par la société OVH :</p>
                        <p>RCS Lille Métropole 424 761 419 00045</p>
                        <p>2 rue Kellermann - 59100 Roubaix - France</p>
                        <p><a href="https://www.ovh.com" className="text-red-500 hover:underline">OVH</a></p>
                        <br/>
                        <p>
                            Conformément aux dispositions du code de la propriété intellectuelle, toute personne qui souhaiterait reproduire
                            le contenu rédactionnel de ce site en vue d’une communication au public doit impérativement solliciter une autorisation
                            préalable auprès d’un des contacts mentionnés ci-dessus. En cas d’absence de réponse, ce silence ne vaudrait pas acceptation.
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Mise en garde</h3>
                        <p>
                            Add-Last s’engage à vérifier le contenu des liens hypertextes pointant sur des pages extérieures au présent site
                            au moment de leur création. Néanmoins, le contenu des sites visés peut changer postérieurement à cette vérification.
                            Nous invitons donc les visiteurs à la plus grande prudence. De même, nous vous prions de bien vouloir nous signaler
                            tout lien hypertexte brisé ou erroné.
                        </p>
                    </section>

                    {/* Politique de confidentialité */}
                    <section className="mb-8">
                        <h2 className="text-2xl font-bold mb-4">Politique de confidentialité</h2>
                        <p>
                            Nous nous engageons à protéger votre vie privée. Contactez-nous si vous avez des questions ou des problèmes concernant
                            l’utilisation de vos données personnelles et nous serons heureux de vous aider. En utilisant ce site ou / et nos services,
                            vous acceptez le traitement de vos données personnelles tel que décrit dans cette politique de confidentialité.
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Définitions</h3>
                        <p><span className=" font-bold">Données personnelles :</span> toute information relative à une personne physique identifiée ou identifiable.</p>
                        <p><span className=" font-bold">Traitement :</span> toute opération ou ensemble d’opérations effectuées sur des données personnelles ou sur des ensembles de données personnelles.</p>
                        <p><span className=" font-bold">Enfant :</span> une personne physique de moins de 16 ans.</p>
                        <p><span className=" font-bold">Nous :</span> Add-Last SAS au capital de 1000€</p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Principes de protection des données</h3>
                        <p>Nous nous engageons à respecter les principes de protection des données suivants :</p>
                        <ul className="list-disc list-inside">
                            <li>Le traitement est légal, juste, transparent. Nos activités de traitement ont des motifs légitimes. Nous considérons toujours vos droits avant de traiter les données personnelles. Nous vous fournirons des informations concernant le traitement sur demande.</li>
                            <li>Le traitement est limité à l’objectif. Nos activités de traitement correspondent à l’objectif pour lequel les données personnelles ont été collectées.</li>
                            <li>Le traitement est effectué avec un minimum de données. Nous collectons et traitons uniquement la quantité minimale de données personnelles requises à quelque fin que ce soit.</li>
                            <li>Le traitement est limité avec une période de temps. Nous ne conserverons pas vos données personnelles plus longtemps que nécessaire.</li>
                            <li>Nous ferons de notre mieux pour assurer l’exactitude des données.</li>
                            <li>Nous ferons de notre mieux pour assurer l’intégrité et la confidentialité des données.</li>
                        </ul>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Droits du sujet de données</h3>
                        <p>Le sujet de données a les droits suivants :</p>
                        <ul className="list-disc list-inside">
                            <li>Droit à l’information – ce qui signifie que vous devez savoir si vos données personnelles sont en cours de traitement ; quelles sont les données collectées, d’où elles proviennent et pourquoi et par qui elles sont traitées.</li>
                            <li>Droit d’accès – ce qui signifie que vous avez le droit d’accéder aux données collectées de / à votre sujet. Cela inclut votre droit de demander et d’obtenir une copie de vos données personnelles recueillies.</li>
                            <li>Droit de rectification – ce qui signifie que vous avez le droit de demander la rectification ou l’effacement de vos données personnelles qui sont inexactes ou incomplètes.</li>
                            <li>Droit d’effacement – ce qui signifie que dans certaines circonstances, vous pouvez demander que vos données personnelles soient effacées de nos archives.</li>
                            <li>Droit de restreindre le traitement – signifiant que certaines conditions s’appliquent, vous avez le droit de restreindre le traitement de vos données personnelles.</li>
                            <li>Droit d’opposition au traitement – ce qui signifie dans certains cas que vous avez le droit de vous opposer au traitement de vos données personnelles, par exemple dans le cas du marketing direct.</li>
                            <li>Droit de s’opposer au traitement automatisé – ce qui signifie que vous avez le droit de vous opposer au traitement automatisé, y compris le profilage; et ne pas être soumis à une décision basée uniquement sur le traitement automatisé. Ce droit, vous pouvez exercer chaque fois qu’il y a un résultat du profilage qui produit des effets juridiques concernant ou vous affectant de manière significative.</li>
                            <li>Droit à la portabilité des données – vous avez le droit d’obtenir vos données personnelles dans un format lisible par machine ou, si cela est faisable, comme un transfert direct d’un processeur à un autre.</li>
                            <li>Droit de déposer une plainte – dans le cas où nous refusons votre demande en vertu des droits d’accès, nous vous fournirons une raison pour laquelle. Si vous n’êtes pas satisfait de la manière dont votre demande a été traitée, veuillez nous contacter.</li>
                            <li>Droit à l’aide de l’autorité de surveillance – ce qui signifie que vous avez le droit à l’aide d’une autorité de surveillance et le droit d’autres recours juridiques tels que réclamer des dommages-intérêts.</li>
                            <li>Droit de retirer votre consentement – vous avez le droit de retirer tout consentement donné pour le traitement de vos données personnelles.</li>
                        </ul>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Données que nous recueillons</h3>
                        <p>
                            <span className=" font-bold">Informations que vous nous avez fournies :</span> Cela peut être votre adresse e-mail,
                            votre nom, votre adresse de facturation, votre adresse personnelle, etc., principalement les informations nécessaires pour vous
                            fournir un produit / service ou pour améliorer votre expérience client.
                        </p>
                        <p>
                            <span className=" font-bold">Informations collectées automatiquement :</span> Ceci inclut les informations stockées
                            automatiquement par les cookies et autres outils de session.
                        </p>
                        <p>
                            <span className=" font-bold">Informations accessibles au public :</span> Nous pouvons collecter des informations à
                            votre sujet qui sont publiquement disponibles.
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Comment utilisons-nous vos données personnelles</h3>
                        <p>Nous utilisons vos données personnelles pour :</p>
                        <ul className="list-disc list-inside">
                            <li>Vous fournir un service.Ceci inclut par exemple l’enregistrement de votre compte; vous fournir d’autres produits et services que vous avez demandés; vous fournir des articles promotionnels à votre demande et communiquer avec vous relativement à ces produits et services; communiquer et interagir avec vous; et vous informer des changements apportés aux services.</li>
                            <li>Améliorer votre expérience client.</li>
                            <li>Remplir une obligation légale ou contractuelle.</li>
                            <li>Vous recontacter si besoin.</li>
                        </ul>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Qui d’autre peut accéder à vos données personnelles</h3>
                        <p>Nous ne partageons pas vos données personnelles avec des étrangers.</p>
                        <ul className="list-disc list-inside">
                            <li>Nos partenaires de traitement: aucun pour le moment.</li>
                            <li>Nos partenaires d’affaires: aucun pour le moment.</li>
                            <li>Tiers connectés: aucun pour le moment.</li>
                        </ul>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Comment nous sécurisons vos données</h3>
                        <p>
                            Nous faisons de notre mieux pour protéger vos données personnelles. Nous utilisons des protocoles sécurisés pour la communication
                            et le transfert de données (tel que HTTPS).
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Enfants</h3>
                        <p>
                            Nous n’avons pas l’intention de recueillir ou sciemment recueillir des informations auprès des enfants. Nous ne ciblons pas les enfants
                            avec nos services.
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Cookies et autres technologies que nous utilisons</h3>
                        <p>
                            Nous utilisons des cookies et / ou des technologies similaires pour analyser le comportement des clients, administrer le site Web,
                            suivre les mouvements des utilisateurs et collecter des informations sur les utilisateurs. Ceci est fait afin de personnaliser et
                            d’améliorer votre expérience avec nous.
                        </p>
                        <p>
                            Un cookie est un petit fichier texte stocké sur votre ordinateur. Les cookies stockent les informations utilisées
                            pour aider à faire fonctionner les sites. Seul notre site peut accéder aux cookies que nous créons. Vous pouvez
                            contrôler vos cookies au niveau du navigateur. Choisir de désactiver les cookies peut vous empêcher d’utiliser certaines fonctions.
                        </p>
                        <p>
                            Nous utilisons des cookies aux fins suivantes :
                        </p>
                        <ul className="list-disc list-inside">
                            <li>Cookies nécessaires : ces cookies sont nécessaires pour utiliser certaines fonctionnalités importantes sur notre site Web, comme la connexion.</li>
                            <li>Cookies de fonctionnalité : ces cookies fournissent des fonctionnalités supplémentaires pour rendre l’utilisation de nos services plus pratique.</li>
                            <li>Cookies analytiques : ces cookies sont utilisés pour suivre l’utilisation et les performances de notre site Web et de nos services.</li>
                            <li>Cookies publicitaires : ces cookies sont utilisés pour diffuser des publicités pertinentes pour vous et vos centres d’intérêt.</li>
                        </ul>
                        <br/>
                        <p>
                            Vous pouvez supprimer les cookies stockés sur votre ordinateur via les paramètres de votre navigateur. Vous pouvez également
                            contrôler certains cookies tiers en utilisant une plate-forme d’amélioration de la confidentialité, telle que
                            <a href="http://optout.aboutads.info/#!/" className="text-red-500 hover:underline">optout.aboutads.info</a> ou
                            <a href="http://www.youronlinechoices.com/" className="text-red-500 hover:underline">youronlinechoices.com</a>.
                            Pour plus d’informations sur les cookies, visitez
                            <a href="http://www.allaboutcookies.org/" className="text-red-500 hover:underline">allaboutcookies.org</a>.
                        </p>
                        <p>
                            Nous utilisons Google Analytics pour mesurer le trafic sur notre site Web. Google a sa propre politique de confidentialité que
                            vous pouvez consulter <a href="https://support.google.com/analytics/answer/6004245" className="text-red-500 hover:underline">ici</a>.
                            Si vous souhaitez désactiver le suivi par Google Analytics, consultez
                            <a href="https://tools.google.com/dlpage/gaoptout" className="text-red-500 hover:underline">la page de désactivation de Google Analytics</a>.
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Informations de contact</h3>
                        <p>
                            Vous pouvez nous contacter via notre adresse e-mail de contact :
                            <a href="mailto:atrewind404@gmail.com" className="text-red-500 hover:underline"> atrewind404@gmail.com</a>
                        </p>
                        <br/>
                        <h3 className="text-xl font-bold mb-4">Modifications de cette politique de confidentialité</h3>
                        <p>
                            Nous nous réservons le droit d’apporter des modifications à cette politique de confidentialité. La dernière modification a été
                            faite le 20/04/2021.
                        </p>
                    </section>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default LegalPage;

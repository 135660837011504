import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface UserChartProps {
    data: { name: string; y: number }[];
    selectedDataType: string;
}

const UserChart: React.FC<UserChartProps> = ({ data, selectedDataType }) => {
    const chartOptions = {
        chart: {
            type: 'column',
            backgroundColor: '#f4f4f4',
            zoomType: 'x', // Activer le zoom sur l'axe des X
        },
        title: {
            text: `Nombre d'utilisateurs par ${selectedDataType === 'age' ? 'âge' : selectedDataType === 'gender' ? 'sexe' : 'newsletter'}`,
        },
        xAxis: {
            categories: data.map(item => item.name),
            title: {
                text: selectedDataType === 'age' ? 'Âge' : selectedDataType === 'gender' ? 'Sexe' : 'Newsletter',
            },
        },
        yAxis: {
            title: {
                text: 'Nombre d\'utilisateurs',
            },
        },
        series: [{
            name: `Nombre d'utilisateurs par ${selectedDataType}`,
            data: data.map(item => item.y),
            color: {
                linearGradient: { x1: 0, y1: 0, x2: 1, y2: 0 }, // Dégradé horizontal
                stops: [
                    [0, '#0077ff'],
                    [0.5, '#a155e3'],

                ]
            },
        }],
        plotOptions: {
            column: {
                colorByPoint: false,  // Utilisation du même gradient pour toutes les barres
                borderWidth: 0,
            },
        },
        credits: {
            enabled: false, // Désactiver le logo Highcharts en bas du graphique
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default UserChart;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'; // Utilisation correcte de jwtDecode

// Importation des icônes
import { IconlyCalendar } from '../../components/icons/ClientPage/IconlyCalendar';
import { IconlyHeart } from '../../components/icons/ClientPage/IconlyHeart';
import { IconlyPlus } from '../../components/icons/ClientPage/IconlyPlus';
import { IconlyTicket } from '../../components/icons/ClientPage/IconlyTicket';
import { IconlyUserData } from '../../components/icons/ClientPage/IconlyUserData';
import { IconlyUserInformations } from '../../components/icons/ClientPage/IconlyUserInformations';

const ClientPage = () => {
    const [user, setUser] = useState<{ first_name: string; last_name: string } | null>(null);
    const [loading, setLoading] = useState(true); // Ajout d'un état pour gérer le chargement des données
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token) {
            // Redirige vers la page de connexion si aucun token n'est trouvé
            navigate('/auth');
            return;
        }

        // Vérifier l'expiration du token
        const isTokenValid = (token: string) => {
            try {
                const decodedToken = jwtDecode<{ exp: number }>(token);
                const currentTime = Date.now() / 1000; // en secondes
                return decodedToken.exp > currentTime; // Le token est-il encore valide ?
            } catch (error) {
                console.error('Erreur lors du décodage du token:', error);
                return false;
            }
        };

        if (!isTokenValid(token)) {
            // Token expiré, rediriger vers la page de connexion
            navigate('/auth');
            return;
        }

        const fetchUserProfile = async () => {
            try {
                const profileResponse = await axios.get('/api/auth/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(profileResponse.data.user);
            } catch (error) {
                console.error('Erreur lors de la récupération des données utilisateur :', error);
                // Redirige vers la page de connexion en cas d'erreur (token invalide ou expiré)
                navigate('/auth');
            } finally {
                setLoading(false); // Fin du chargement des données
            }
        };

        fetchUserProfile();
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token'); // Supprime le token du localStorage
        navigate('/'); // Redirige vers la page d'accueil
    };

    if (loading) {
        // Afficher un indicateur de chargement tant que les données ne sont pas récupérées
        return <div>Chargement...</div>;
    }

    return (
        <div className="flex flex-col min-h-screen font-binaria">
            {/* Header */}
            <Header />

            {/* Message de bienvenue */}
            <main className="flex-grow p-8">
                <div className="flex justify-between items-center mb-8 mx-4 md:mx-8">
                    <h1 className="text-2xl md:text-4xl font-bold">Votre compte</h1>
                    <div className="text-lg md:text-2xl">
                        {user ? `Bienvenue, ${user.first_name} ${user.last_name}` : 'Bienvenue !'}
                    </div>
                </div>

                {/* Grid Layout Responsive */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-4 md:mx-8">
                    {/* Informations */}
                    <button
                        onClick={() => navigate('/informations')}  // Redirection vers la page Informations
                        className="p-4 md:p-6 bg-white border border-gray-300 rounded-md text-gray-500 group hover:text-black transition-all shadow-md text-sm md:text-lg flex flex-col items-center"
                    >
                        <IconlyUserInformations size={28} color="#000" />
                        <span className="mt-6 font-semibold group-hover:text-black">INFORMATIONS</span>
                    </button>

                    {/* Adresse */}
                    <button
                        onClick={() => navigate('/addresses')}  // Redirection vers la page Adresses
                        className="p-4 md:p-6 bg-white border border-gray-300 rounded-md text-gray-500 group hover:text-black transition-all shadow-md text-sm md:text-lg flex flex-col items-center"
                    >
                        <IconlyPlus size={28} color="#000" />
                        <span className="mt-6 font-semibold group-hover:text-black">CONSULTER OU MODIFIER VOTRE ADRESSE</span>
                    </button>

                    {/* Historique et détails des commandes */}
                    <button
                        onClick={() => navigate('/orders')}  // Redirection vers la page des commandes
                        className="p-4 md:p-6 bg-white border border-gray-300 rounded-md text-gray-500 group hover:text-black transition-all shadow-md text-sm md:text-lg flex flex-col items-center"
                    >
                        <IconlyCalendar size={28} color="#000" />
                        <span className="mt-6 font-semibold group-hover:text-black">HISTORIQUE ET DÉTAILS DE MES COMMANDES</span>
                    </button>

                    {/* Avoirs */}
                    <button
                        onClick={() => navigate('/credits')}  // Redirection vers la page des avoirs
                        className="p-4 md:p-6 bg-white border border-gray-300 rounded-md text-gray-500 group hover:text-black transition-all shadow-md text-sm md:text-lg flex flex-col items-center"
                    >
                        <IconlyTicket size={28} color="#000" />
                        <span className="mt-6 font-semibold group-hover:text-black">AVOIRS</span>
                    </button>

                    {/* Mes données personnelles */}
                    <button
                        onClick={() => navigate('/personal-data')}  // Redirection vers la page des données personnelles
                        className="p-4 md:p-6 bg-white border border-gray-300 rounded-md text-gray-500 group hover:text-black transition-all shadow-md text-sm md:text-lg flex flex-col items-center"
                    >
                        <IconlyUserData size={28} color="#000" />
                        <span className="mt-6 font-semibold group-hover:text-black">MES DONNÉES PERSONNELLES</span>
                    </button>

                    {/* Mes listes */}
                    <button
                        onClick={() => navigate('/lists')}  // Redirection vers la page des listes
                        className="p-4 md:p-6 bg-white border border-gray-300 rounded-md text-gray-500 group hover:text-black transition-all shadow-md text-sm md:text-lg flex flex-col items-center"
                    >
                        <IconlyHeart size={28} color="#000" />
                        <span className="mt-6 font-semibold group-hover:text-black">MES LISTES</span>
                    </button>
                </div>
            </main>

            {/* Bouton de déconnexion */}
            <footer className="p-4 bg-gray-100 text-center">
                <button
                    onClick={handleLogout}
                    className="text-red-500 text-sm font-semibold hover:underline"
                >
                    Déconnexion
                </button>
            </footer>

            <Footer />
        </div>
    );
};

export default ClientPage;
